import { useState } from 'react';
import styled from '@emotion/styled';
import StyledLink from '@components/Link';
import ROUTES from '@utils/routes';
import STRINGS from '@utils/strings';
import RES from '@utils/resources';
import BurgerIcon from '@components/Burger';
import { SHOW_FAQ, WAX_SIGN_IN, SHOW_BUY_PAGE, SHOW_PACK_HISTORY } from '@utils/globals';
import Login from '@components/Login';
import { withUAL } from 'ual-reactjs-renderer';

const StyledHeader = styled.div(({ theme }) => ({
    padding: `${theme.spacing.xs} ${theme.spacing.s}`,
    height: theme.size.headerHeight,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 999,
    backgroundColor: theme.colors.backgroundColor
}));

const Links = styled.div(({ theme, open }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100vw',
    backgroundColor: theme.colors.backgroundColor,
    transform: open ? 'translateY(100%)' : 'translateY(-100%)',
    transition: theme.transition.default,
    padding: theme.spacing.xs,
    [theme.mediaQuery.tabletLandscapeUp]: {
        transform: 'none',
        flexDirection: 'row',
        position: 'relative',
        top: 'initial',
        left: 'initial',
        width: 'initial',
        padding: 0,
        backgroundColor: 'transparent'
    }
}));

const Logo = styled.a(({ theme }) => ({
    cursor: 'pointer',
    height: 20,
    zIndex: 999,
    '&:hover': {
        textDecoration: 'none'
    }
}));

function Header({ ual }) {
    const [ burgerOpen, setBurgerOpen ] = useState(false);

    function handleBurgerOpen() {
        setBurgerOpen(false);
    }

    return (
        <StyledHeader>
            <Logo href='#' onClick={handleBurgerOpen}>
                <img style={{ height: '100%', objectFit: 'contain' }} src={RES.images.icon} />
            </Logo>
            <Links open={burgerOpen} >
                <StyledLink header href={`/#${ROUTES.home}`} onClick={handleBurgerOpen}>
                    {STRINGS.home}
                </StyledLink>
                {
                    SHOW_FAQ ?
                        <StyledLink header href={`/#${ROUTES.faq}`} onClick={handleBurgerOpen}>
                            {STRINGS.faq}
                        </StyledLink>
                        : null
                }
                {
                    SHOW_BUY_PAGE ?
                        <StyledLink header href={`/#${ROUTES.buypacks}`} onClick={handleBurgerOpen}>
                            {STRINGS.shop}
                        </StyledLink>
                        : null
                }
                {
                    WAX_SIGN_IN && ual.activeUser ?
                        <>
                            <StyledLink header href={`/#${ROUTES.inventory}`} onClick={handleBurgerOpen}>
                                {STRINGS.inventory}
                            </StyledLink>
                            <StyledLink header href={`/#${ROUTES.reader}`} onClick={handleBurgerOpen}>
                                {STRINGS.reader}
                            </StyledLink>
                            {
                                SHOW_PACK_HISTORY ?
                                    <StyledLink header href={`/#${ROUTES.packrips}`} onClick={handleBurgerOpen}>
                                        {STRINGS.packRips}
                                    </StyledLink> : null
                            }
                        </>
                        :
                        null
                }
                { WAX_SIGN_IN ? <Login /> : null }
            </Links>
            <BurgerIcon burgerOpen={burgerOpen} setBurgerOpen={setBurgerOpen} />
        </StyledHeader>
    );
}

export default WAX_SIGN_IN ? withUAL(Header) : Header;
