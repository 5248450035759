export const RES = {
    images: {
        icon: 'https://facings.fra1.digitaloceanspaces.com/cryptowriter/finney%20wordmark.png',
        logo: 'https://facings.fra1.digitaloceanspaces.com/cryptowriter/finney-picture.png',

        pack: 'https://darkpinup.mypinata.cloud/ipfs/QmTGa4xzg5Wdb6LUNvv1QLkq2hB5uGLFMWZoDytCjZCmJH',
        box: 'https://darkpinup.mypinata.cloud/ipfs/QmZJq57LdPgViEdMGsBwdiGnADGakwVdpXLy8uZoFV4xTk',

        atomicHubLogo: '@images/atomichub.png',
        waxStashLogo: '@images/waxStash.png',
        nftHiveLogo: '@images/nftHive.svg',

        backgroundLarge: 'https://facings.fra1.digitaloceanspaces.com/cryptowriter/background-desktop.png',
        backgroundSmall: 'https://facings.fra1.digitaloceanspaces.com/cryptowriter/background-mobile.png'
    },

    videos: {
        packOpenAnimation: 'https://facings.fra1.digitaloceanspaces.com/cryptowriter/standard.mp4',
        boxOpenAnimation: 'https://facings.fra1.digitaloceanspaces.com/cryptowriter/deluxe.mp4'
    },

    samples: [
        {
            srcImg: 'QmemDmMWc8NpJvihicwwLMtgFzZcjnmQQdaGMgPUC25yus',
            caption: 'Base - Ink',
            type: 'img'
        },
        {
            srcImg: 'QmdnGcSP7G2AP8b8kmjHYV7mqmTy4KjXEKZS8RWVusXMam',
            caption: 'Base - Colour',
            type: 'img'
        },
        {
            srcImg: 'QmbV8sC4Z8S5eETb1W9P3uGrDsRxbNamV8KCc8wsAufkz1',
            caption: 'Timelapse',
            type: 'video'
        },
        {
            srcImg: 'QmQbuHhJSjo661aPvBUoT9rX9JAteekCJ9zoZxMpLjaJGP',
            caption: 'Narrated - Ink',
            type: 'img'
        },
        {
            srcImg: 'QmRiQd6fpvUdt47cgTb1AwCHoj5ofyDoeMCvytSEcVtNN2',
            caption: 'Narrated - Colour',
            type: 'img'
        },
        {
            srcImg: 'QmNSzn93yM1qAJWU9koSRw2v6PPUhE4bTkzYc7pRJzLQNG',
            caption: 'Platinum Frequency',
            type: 'video'
        },
        {
            srcImg: 'QmTSjCtg97Yy9X9CJP2ojNC7kwdWVnF2zohDXARznUr5ES',
            caption: 'Gold Frequency',
            type: 'video'
        },
        {
            srcImg: 'QmPBu35UkjsqRoTNMAgNZNrRN1zkuxh2tmu2MahVcq6Tzz',
            caption: 'Dark Rhodium Frequency',
            type: 'video'
        }
    ],

    comicBook: [
        {
            title: 'FINNEY Vol.1 IMMUTABLE JOURNEY',
            id: 'vol. 1',
            button: 'View Vol. 1',
            cover: 'https://darkpinup.mypinata.cloud/ipfs/QmcLyUJ4u4jJEu9n4Go58q7Dbw8gxNDqq1xqrw41iWpn3p',
            vol: [
                {
                    srcImg: 'QmcLyUJ4u4jJEu9n4Go58q7Dbw8gxNDqq1xqrw41iWpn3p'
                },
                {
                    srcVideo: 'QmdmeGHKyNiNTrfM69MQt2Y8QKm6ePjJ3cnJT8yHqF8K5f',
                    srcBackImg: 'QmcShSMWBUTgF9S7ZgnheSTH1o5NssR4tpD9YS49RVBRvm',
                    srcBackImg2: 'Qmb21fhRkusNj16s25AC7aTqiXUmBJ8vCNPUEDuofEBxBC'
                },
                {
                    srcVideo: 'QmR8uCCXGWVFWo4Tp23JyvS1P9QmkFusmbn2KRXVPbESaG',
                    srcBackImg: 'QmVevGKj6XTASXFZBcBbJynNGivTkiSc3RLhvheG13PQAw',
                    srcBackImg2: 'QmezS7YonzUwDfB1fAEHTMs7ozYRgkdUVyb2fmqXUjq294'
                },
                {
                    srcVideo: 'QmQFMeuWidvg52vQ4S56X7r4StU5qPPwqvcTKrid9jHZxc',
                    srcBackImg: 'QmTpGe3Zs78u6xmsaNz8iDDcDsZnsC29kcrsuegrgDAXiW',
                    srcBackImg2: 'QmTU2USNUn9s1qj9LUsKV9KPZmosfNSH1dnmrA4sGVpoYd'
                },
                {
                    srcVideo: 'QmfEYw2Di5fLp7HeWhD53XvAWmXdkeTYoeRwnqsroQf6oq',
                    srcBackImg: 'QmSx2zHEeX1q8Egf1vtouDFhuRx6S9JtXEyAwA5yf1hU7t',
                    srcBackImg2: 'Qmb2GMKHkJABtNCyrKxS2zLyPDPdRSiTaC1ofUBZAorTw6'
                },
                {
                    srcVideo: 'QmbNDFsztSzo8h2TQqML5HqqiwtpjdYL6uXQh8maUSfTVe',
                    srcBackImg: 'QmVQEVDxGArWjaaDDt6rLDaN2zGiopQqWze3CN52UgZPKJ',
                    srcBackImg2: 'QmPrvWKKfGnGXr81897hMPaDM5c4A9PhG5XcCh4nwitMTZ'
                },
                {
                    srcVideo: 'QmTffTAVP3uiREx5TFY31oGyEJ9YbicqpkriNNmidN8bEe',
                    srcBackImg: 'QmakCrzW9wBoHuLXW16o1YiJ7AKsUqAKow4m47rPEhBHKZ',
                    srcBackImg2: 'Qmf2so5EJGVcb7kaVq32SfzF5LczpDtSHwm8FoBYAyx6TH'
                },
                {
                    srcVideo: 'QmeZ2w14xfui4Fa1qtstUcMFH9rbdCAi2sFPFFBqzpuJnC',
                    srcBackImg: 'QmYiPPMDpqDER2V4V8xQGWAgrq6Ms4bQHyP7PmU9T7RnPQ',
                    srcBackImg2: 'Qmezg2qPyNLmKZcc2xiDagASvSyw9aawiLRWboT2B3GDyt'
                },
                {
                    srcVideo: 'QmZA42Lejn69uvAZnT7M5Pja9jGQe7AYWeV1tfuadggft7',
                    srcBackImg: 'QmQi9StT8sxQ3ee87iCWKybS3aUpBw3iY82DKgFwTF6nf9',
                    srcBackImg2: 'QmW2U97DGCZY2fLcCphuyg1oaMKnHjbpTq34bxrBtZeqP9'
                },
                {
                    srcVideo: 'Qmf1RuMhxqbyxYYfSF8HfYHXDqU8gjaWyHaXmY1CwS4mU8',
                    srcBackImg: 'QmV6np2VRMsyPUsfSyTse8HDsMJEuDwwQorfctR9Lc5rCV',
                    srcBackImg2: 'QmQ7k9VuoJdVnDTa33hufaNvHH5nGAPcapNRJmEaY3kEkU'
                },
                {
                    srcVideo: 'QmYBmoAtVDBDJDfdtrMpLBCyBJLyqf2Y25u4432fKsmz1X',
                    srcBackImg: 'QmcwAbjEgv82dYPmn5S4aAQSkCZGgeY7XP8JshYmEj1RRa',
                    srcBackImg2: 'QmZsch4dLuxSJRMfrVXYrZaN5Ltq3Y7zuwCFPSckYWD83j'
                },
                {
                    srcVideo: 'QmRufJDJTnY2QjWjFUHEN728pPhnuB9D1P5xgXCyMiBAhY',
                    srcBackImg: 'QmUPRZZ6kPfrtuF7YtWpnDq3khqvNtgms8uLBXnXdNt6A7',
                    srcBackImg2: 'QmaKkZN5E8TNmxDvfrqRNHm7xpBEc1GzrNNZFJ21Cvpu42'
                },
                {
                    srcVideo: 'QmXjuKankSPVh1B8T7CPFJukv8424YPA6Ykw9hGsQpHhuw',
                    srcBackImg: 'QmdLRBHduztzJ1ESLFJDYKoqvxNETqGn5Hgc7pWTcbhiL1',
                    srcBackImg2: 'QmUAzkXyLZmyNVvw7Me7yeEjRGrUtqkU9JRTg5pZggLEQ9'
                },
                {
                    srcVideo: 'QmcX85YPQByqBW9AfjjS2wET1xeA55YUhmTF8F3iDXpnsn',
                    srcBackImg: 'QmSjtVcjx7cN7UG7A5xv59U8AgtAHvxVg85fVM1V7FJQXB',
                    srcBackImg2: 'QmUk1K7YcZe7vdcR5EjtkLAx9ehNAjXXA3pGd99EwG2SPN'
                },
                {
                    srcVideo: 'QmYjTMqCu1X2ptaAmA9iSMbpVWAk7ph5VvUUMpXZZFtD5q',
                    srcBackImg: 'QmTwo5tCDjfFt9smrDyLqzqp4cTp5kZjPkkCyujzerZRp5',
                    srcBackImg2: 'QmVbE3BWBkjown8zMh1CY823Ksjw5D91MFKrU1ztDip4q2'
                },
                {
                    srcVideo: 'QmRQhbd1vXS3jRzSyVM8hZaerQiYktoTmD3LuH9ykppX8A',
                    srcBackImg: 'QmQP8QvtegzTboTEZEq8YdpvN5Lj4ytzjraHYgPMWTyt96',
                    srcBackImg2: 'QmbGA6JDdbFz4gGFrfwv98AsLHyr9yhsX4yF5r4JMswdxc'
                },
                {
                    srcVideo: 'QmVGJrJA3wbG5s2V8kuC447cYzUoGdtD3zwi4BHQnwjtzh',
                    srcBackImg: 'QmYkVXMRZ7HVy3b2UVDsZH5p26u8GPeH1orMPg4aA9XmXn',
                    srcBackImg2: 'QmRaK51TwUcTJLjZEBqvCyWGX8hpJCZWgGmqNuJHQBzg9x'
                },
                {
                    srcVideo: 'QmbEybGUvmo4nkrU5Ja6sV6Jc3Tch2oHLtdbEzoZtnKKuA',
                    srcBackImg: 'QmZvyTY96sEds7ZkAVwLc3GLV6TYQYQSYPHNGWhYEM5vdA',
                    srcBackImg2: 'QmVntjj8gqdBwUeJbyQY336K2sLQEDJsqDRftChBxbW3iZ'
                },
                {
                    srcVideo: 'QmQrYf7chVD5CRvnan7e4i8KRTYCgmJS3TCdh5dX4THWoc',
                    srcBackImg: 'QmWg6tW8wVdL9NF5scw3yVmMjtT1MHaH1C4V8notazXFjp',
                    srcBackImg2: 'QmSsKBi3kMHmkKqjQPCtdHTSGWS2Ukr4255m1yHHvKcLgW'
                },
                {
                    srcVideo: 'QmTBfwq3fXAZiUmZHDMmyGpWZNXMEu6UNVMjyMmbedzCfs',
                    srcBackImg: 'QmbfZh3Cg2xXdhjLCWNX5e8X23ubBsypvi5gJPnauEpLQT',
                    srcBackImg2: 'Qmefdb62ocuSixpJvhAZY9oijtMtDFLkBNnRpvTgwvfq6W'
                },
                {
                    srcVideo: 'QmXMQUVb9r43zbmxB3Hpt4PxUQ9C5W9Ctb7nHfrJwwYfBe',
                    srcBackImg: 'QmVyGz5ZwWEGKs7VTW8PHGXgLDSVgARJTxLjNdmwB2i5qp',
                    srcBackImg2: 'QmUV4UoS4WTQqkwsHFx8cvVvxx9STX2qG5Xg4Kp7ySDWnk'
                },
                {
                    srcVideo: 'QmcSJT5exNwxQzZxiWmfo1zP3UfguxLWqbBmBv7eQpuHhF',
                    srcBackImg: 'QmXwDRHGBUQugKFF4rnK4gPCuXjthv92w2wQKmL6UENGHp',
                    srcBackImg2: 'QmSao3HKGVwHDALhkqrH4kPSFpeXFUUxu3WsvZ5V8hYJHg'
                },
                {
                    srcImg: 'QmaGqAgVYDpq4rNufT9aSknZneL7tZ9rUxvEbMosLEBbcm'
                }
            ],
            ahaMoment: [
                'QmTFrXpTFtQUzaRync4KEwKVi17GTNogTwhKkkArWNwjQ8',
                'QmTqfMqGPtUbp5q3TJ2RCi1AyM1YuxfxE3YFmMB4E3RH2f',
                'QmVGWTRQbXRCnoeWFzGZw5xY32EHPegiir3hMa4RKaTGYS'
            ]
        }
    ],

    secondaryMarketLinks: {
        atomicHub: {
            label: "AtomicHub",
            link: "https://wax.atomichub.io/market?collection_name=writer"
        },
        atomicHubInventory: {
            label: "AtomicHub Inventory",
            link: "https://wax.atomichub.io/profile/{0}?collection_name=writer"
        },
        atomicHubEntireCollection: {
            label: "AtomicHub Dark Pinup entire Collection",
            link: "https://wax.atomichub.io/tools/book/writer/{0}"
        },
        waxStash: {
            label: "WAXStash",
            link: "https://www.waxstash.com/markets?collection_name=writer"
        },
        nftHive: {
            label: "NFTHive",
            link: "https://nfthive.io/collection/writer"
        }
    },

    externalLinks: {
        wax: {
            label: "Powered by WAX",
            link: "https://wax.io"
        },
        facings: {
            label: "Built by FACINGS",
            link: "https://facings.io"
        },
        facingsTelegram: {
            label: "FACINGS telegram group",
            link: "https://t.me/facings"
        },
        facingsDiscord: {
            label: "FACINGS discord channel",
            link: "https://discord.gg/mW2654Nw3Z"
        },
        facingsTwitter: {
            label: "FACINGS twitter",
            link: "https://twitter.com/FACINGSofficial"
        },
        atomicAssets: {
            label: "Built with Atomic Assets",
            link: "https://wax.atomichub.io/"
        },
        telegram: {
            label: "Telegram group",
            link: "https://t.me/cryptowriter_public"
        },
        twitter: {
            label: "Twitter account",
            link: "https://twitter.com/CryptoFinney"
        },
        discord: {
            label: "Discord",
            link: "https://discord.gg/CKqpc229Yj"
        },
        finney: {
            label: "Finney",
            link: "https://finney.world/"
        }
    }
};

export default RES;
