const IS_PRODUCTION = process.env.NODE_ENV === 'production';

function buildMap() {
    let map = new Map();
    if (IS_PRODUCTION) {
        map.set('21', [ '256706', '256685' ]);
        map.set('20', [ '256705', '256684' ]);
        map.set('19', [ '256704', '256683' ]);
        map.set('18', [ '256703', '256682' ]);
        map.set('17', [ '256702', '256681' ]);
        map.set('16', [ '256701', '256680' ]);
        map.set('15', [ '256700', '256679' ]);
        map.set('14', [ '256699', '256678' ]);
        map.set('13', [ '256698', '256677' ]);
        map.set('12', [ '256697', '256676' ]);
        map.set('11', [ '256696', '256675' ]);
        map.set('10', [ '256695', '256674' ]);
        map.set('9', [ '256694', '256673' ]);
        map.set('8', [ '256693', '256672' ]);
        map.set('7', [ '256692', '256671' ]);
        map.set('6', [ '256691', '256670' ]);
        map.set('5', [ '256690', '256669' ]);
        map.set('4', [ '256689', '256668' ]);
        map.set('3', [ '256688', '256667' ]);
        map.set('2', [ '256687', '256666' ]);
        map.set('1', [ '256686', '256665' ]);
    } else {
        map.set('21', [ '155729', '155750' ]);
        map.set('20', [ '155728', '155749' ]);
        map.set('19', [ '155727', '155748' ]);
        map.set('18', [ '155726', '155747' ]);
        map.set('17', [ '155725', '155746' ]);
        map.set('16', [ '155724', '155745' ]);
        map.set('15', [ '155723', '155744' ]);
        map.set('14', [ '155722', '155743' ]);
        map.set('13', [ '155721', '155742' ]);
        map.set('12', [ '155720', '155741' ]);
        map.set('11', [ '155719', '155740' ]);
        map.set('10', [ '155718', '155739' ]);
        map.set('9', [ '155717', '155738' ]);
        map.set('8', [ '155716', '155737' ]);
        map.set('7', [ '155715', '155736' ]);
        map.set('6', [ '155714', '155735' ]);
        map.set('5', [ '155713', '155734' ]);
        map.set('4', [ '155712', '155733' ]);
        map.set('3', [ '155711', '155732' ]);
        map.set('2', [ '155710', '155731' ]);
        map.set('1', [ '155709', '155730' ]);
    }
    return map;
}

const comicBookEnablers =
    IS_PRODUCTION ?
        [ '256835', '256834' ]
        :
        [ '155877', '155878' ];

const ahaMomentEnablers = buildMap();

export const KEY_ASSETS = { comicBookEnablers, ahaMomentEnablers };