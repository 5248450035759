import React, { useState, useEffect } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { getDropInfo } from '@api/api';
import { PACK_DROP_ID, BOX_DROP_ID, WAX_SIGN_IN } from '@utils/globals';
import IsReleased from '@components/IsReleased';
import StyledButton from '@components/Button';
import StyledConfirmationModal from '@components/ConfirmationModal';
import StyledErrorModal from '@components/ErrorModal';
import StyledSuccessModal from '@components/SuccessModal';
import StyledInput from '@components/Input';
import Ribbon from '@components/Ribbon';
import Loading from '@components/Loading';
import { buyPacks } from '@api/api';
import STRINGS from '@utils/strings';
import RES from '@utils/resources';
import { isCPUErrorMessage, isNETErrorMessage } from '@utils/utils';
import { withUAL } from 'ual-reactjs-renderer';

import atomicHubLogo from '@images/atomichub.png';
import waxStashLogo from '@images/waxStash.png';
import nftHiveLogo from '@images/nftHive.svg';

const PageSizeContainer = styled.div(({ theme }) => ({
    minHeight: `calc(100vh - ${theme.size.headerHeight})`,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage: `url(${RES.images.backgroundSmall})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    [ theme.mediaQuery.tabletUp ]: {
        backgroundImage: `url(${RES.images.backgroundLarge})`
    }
}));

const MainContentContainer = styled.div(({ theme }) => ({
    backgroundColor: theme.colors.backgroundColor,
    paddingTop: theme.spacing.m,
    padding: theme.spacing.xs,
    marginTop: theme.spacing.xs,
    marginBottom: theme.spacing.xs,
    borderRadius: theme.borderRadius.l,
    bottom: '5vh',
    textAlign: 'center',
    [ theme.mediaQuery.tabletUp ]: {
        padding: theme.spacing.l
    }
}));

const Container = styled.div(({ theme }) => ({
    paddingTop: theme.spacing.m,
    display: 'block',
    textAlign: 'center',
    gap: theme.spacing.m,
    [theme.mediaQuery.tabletLandscapeUp]: {
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'space-evenly'
    }
}));

const ButtonContainer = styled.div(({ theme, IsReleased }) => {
    let releasedStyle = IsReleased ? {} : { filter: 'grayscale(0)' };

    return {
        textAlign: 'center',
        marginTop: theme.spacing.xxs,
        marginBottom: theme.spacing.xl,
        ...releasedStyle
    };
});

const PackDetailsContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing.l,
    color: theme.colors.tertiaryLight
}));

const PackDetailsTitle = styled.h5(({ theme }) => ({
    ...theme.typography.h5,
    color: theme.colors.tertiary,
    fontWeight: 'bold',
    marginBottom: theme.spacing.xs
}));

const PackDetailsList = styled.ul(({ theme }) => ({
    ...theme.typography.p,
    marginTop: theme.spacing.xxs,
    color: theme.colors.tertiary,
    listStyle: 'none',
    textAlign: 'left',
    padding: 0,
    [ theme.mediaQuery.tabletUp]: {
        width: 395
    }
}));

const PackDetailItem = styled.li(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    borderBottom: `thin solid ${theme.colors.primary}69`
}));

const PackDetailsDescription = styled.p(({ theme }) => ({
    ...theme.typography.p,
    marginTop: theme.spacing.m,
    color: theme.colors.tertiary,
    maxWidth: '65ch'
}));

const Image = styled.img(({ theme, IsReleased }) => {
    let releasedStyle = IsReleased ? {} : { opacity: 'grayscale(0)' };

    return {
        height: '30vh',
        maxHeight: '400px',
        margin: `${theme.spacing.m} ${theme.spacing.m} ${theme.spacing.xxs} ${theme.spacing.m}`,
        objectFit: 'contain',
        userSelect: 'none',
        ...releasedStyle
    };
});

const Logo = styled.img(({ theme }) => ({
    width: '40vw',
    objectFit: 'contain',
    userSelect: 'none',
    [theme.mediaQuery.tabletLandscapeUp]: {
        width: '25vw'
    }
}));

const ButtonCaption = styled.p(({ theme }) => ({
    ...theme.typography.pTiny,
    color: theme.colors.tertiary,
    marginTop: theme.spacing.xxs
}));

const CPUErrorMessage = styled.p(({ theme }) => ({
    ...theme.typography.p,
    margin: theme.spacing.s,
    textAlign: 'initial',
    a: {
        color: theme.colors.primary,
        '&:hover': {
            color: theme.colors.primaryDark
        }
    }
}));

const Prices = styled.h6(({ theme }) => ({
    ...theme.typography.h6,
    marginTop: theme.spacing.s,
    textAlign: 'center',
    fontWeight: 'bold',
    color: theme.colors.secondary
}));

const SecondaryMarketContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: `${theme.spacing.l} auto`,
    padding: theme.spacing.xs,
    width: 'max-content',
    color: theme.colors.backgroundColor,
    backgroundColor: theme.colors.secondaryLighter,
    borderRadius: theme.borderRadius.m
}));

const SecondaryMarketLogosContainer = styled.div(({ theme }) => ({
    width: theme.size.secondaryMarketContainerWidth,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
}));

const SecondaryMarketImage = styled.img(({ theme }) => ({
    padding: theme.spacing.xs,
    width: theme.size.secondaryMarketLogosWidth,
    objectFit: 'contain'
}));

function BuyPacks({ ual }) {
    const theme = useTheme();
    const isReleased = IsReleased();

    const [ packInfo, setPackInfo ] = useState(null);
    const [ boxInfo, setBoxInfo ] = useState(null);
    const [ showBuyPackConfirmation, setShowBuyPackConfirmation ] = useState(false);
    const [ showBuyBoxConfirmation, setShowBuyBoxConfirmation ] = useState(false);
    const [ showBuySuccessMessage, setShowBuySuccessMessage ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ packPurchaseAmount, setPackPurchaseAmount ] = useState(1);
    const [ boxPurchaseAmount, setBoxPurchaseAmount ] = useState(1);
    const [ packPrice, setPackPrice ] = useState(null);
    const [ boxPrice, setBoxPrice ] = useState(null);

    const customInputStyle = {
        alignItems: 'center',
        marginBottom: theme.spacing.s,
        input: {
            maxWidth: '70px'
        }
    };

    useEffect(function() {
        getDropInfo(PACK_DROP_ID, setPackInfo, (errorMessage) => setErrorMessage(errorMessage));
        getDropInfo(BOX_DROP_ID, setBoxInfo, (errorMessage) => setErrorMessage(errorMessage));
    }, []);

    useEffect(function() {
        setPackPrice(generateAssetPriceInfo(packInfo, packPurchaseAmount));
    }, [ packInfo, packPurchaseAmount ]);

    useEffect(function() {
        setBoxPrice(generateAssetPriceInfo(boxInfo, boxPurchaseAmount));
    }, [ boxInfo, boxPurchaseAmount ]);

    const generateAssetPriceInfo = (assetInfo, assetAmount) => {
        let assetPriceString = STRINGS.loadingEl;

        if (assetInfo && assetAmount) {
            let SingleAssetWAXPrice = parseFloat(assetInfo.formattedWAXPrice);
            let WAXPrice = SingleAssetWAXPrice * assetAmount;

            let SingleAssetUSDPrice = parseFloat(assetInfo.formattedUSDPrice);
            let USDPrice = SingleAssetUSDPrice * assetAmount;

            assetPriceString = `${WAXPrice.toFixed(2)} ${STRINGS.wax} ($${USDPrice.toFixed(2)})`;
        }

        return assetPriceString;
    };

    const showBuyBoxConfirmationModal = () => {
        if (!WAX_SIGN_IN) { return; }
        if (ual.activeUser) {
            setShowBuyBoxConfirmation(true);
        } else {
            ual.showModal();
        }
    };

    const showBuyPackConfirmationModal = () => {
        if (!WAX_SIGN_IN) { return; }
        if (ual.activeUser) {
            setShowBuyPackConfirmation(true);
        } else {
            ual.showModal();
        }
    };

    const onBuyPacks = () => {
        if (!WAX_SIGN_IN) { return; }
        const success = () => {
            setShowBuyPackConfirmation(false);
            setShowBuySuccessMessage(true);
        };
        buyPacks(ual.activeUser, packInfo, packPurchaseAmount, success, (errorMessage) => setErrorMessage(errorMessage));
    };

    const onBuyBox = () => {
        if (!WAX_SIGN_IN) { return; }
        const success = () => {
            setShowBuyBoxConfirmation(false);
            setShowBuySuccessMessage(true);
        };
        buyPacks(ual.activeUser, boxInfo, boxPurchaseAmount, success, (errorMessage) => setErrorMessage(errorMessage));
    };

    const getBuyPacksLabel = () => {
        let label = STRINGS.comingSoon;
        if (isReleased) {
            label =  packPurchaseAmount <= 1 ?
                STRINGS.buyAPack :
                STRINGS.buyPacksFor.replace("{0}", packPurchaseAmount).replace("{1}", packPrice);
        }
        if (packInfo && packInfo.available === 0) {
            label = STRINGS.soldOut;
        }
        return label;
    };

    const getBuyBoxLabel = () => {
        let label = STRINGS.comingSoon;
        if (isReleased) {
            label =  boxPurchaseAmount <= 1 ?
                STRINGS.buyABox :
                STRINGS.buyBoxesFor.replace("{0}", boxPurchaseAmount).replace("{1}", boxPrice);
        }
        if (boxInfo && boxInfo.available === 0) {
            label = STRINGS.soldOut;
        }
        return label;
    };

    const renderSecondaryMarketLinks = () => {
        return (boxInfo && boxInfo.available === 0) || (packInfo && packInfo.available === 0) ? (
            <SecondaryMarketContainer>
                <h2>{STRINGS.secondaryMarketTitle}</h2>
                <SecondaryMarketLogosContainer>
                    <a href={RES.secondaryMarketLinks.nftHive.link} target='_blank' rel='noreferrer noopener'>
                        <SecondaryMarketImage src={nftHiveLogo} alt={RES.secondaryMarketLinks.nftHive.label} />
                    </a>
                    <a href={RES.secondaryMarketLinks.atomicHub.link} target='_blank' rel='noreferrer noopener'>
                        <SecondaryMarketImage src={atomicHubLogo} alt={RES.secondaryMarketLinks.atomicHub.label} />
                    </a>
                    <a href={RES.secondaryMarketLinks.waxStash.link} target='_blank' rel='noreferrer noopener'>
                        <SecondaryMarketImage src={waxStashLogo} alt={RES.secondaryMarketLinks.waxStash.label} />
                    </a>
                </SecondaryMarketLogosContainer>
            </SecondaryMarketContainer>
        ): null;
    };

    const onAmountChange = (newAmount, updateFunction, min, max) => {
        if (newAmount <= min) {
            updateFunction(min);
        } else if (newAmount >= max) {
            updateFunction(max);
        } else {
            updateFunction(newAmount);
        }
    };

    return (
        packInfo && boxInfo ?
            <PageSizeContainer>
                {
                    isReleased ? null : <Ribbon />
                }
                <MainContentContainer>
                    <Container>
                        <div>
                            <Image alt={STRINGS.campaignPack} src={RES.images.pack} />
                            <ButtonContainer>
                                <StyledInput
                                    type="number"
                                    max={packInfo.account_limit}
                                    min={1}
                                    value={packPurchaseAmount}
                                    onChange={(e) => onAmountChange(e.target.value, setPackPurchaseAmount, 1, packInfo.account_limit)}
                                    label={STRINGS.packsAmount}
                                    infoText={STRINGS.packPurchaseLimit.replace("{0}", packInfo.account_limit).replace("{1}", packInfo.account_limit_cooldown/60)}
                                    style={customInputStyle}
                                />
                                <StyledButton disabled={!isReleased || (packInfo && packInfo.available === 0) || packPurchaseAmount < 1} onClick={showBuyPackConfirmationModal} >
                                    { getBuyPacksLabel() }
                                </StyledButton>
                                <Prices>{packPrice}</Prices>
                                <ButtonCaption>
                                    {STRINGS.packContents}
                                </ButtonCaption>
                                <ButtonCaption>
                                    {`${packInfo.available} / ${packInfo.max_claimable}`}
                                </ButtonCaption>
                            </ButtonContainer>
                            <PackDetailsContainer>
                                <PackDetailsTitle>{STRINGS.packProbabilities}</PackDetailsTitle>
                                <PackDetailsList>
                                    {
                                        STRINGS.packChances.map((line, index) => (
                                            <PackDetailItem key={index}>
                                                <span>
                                                    {line.rarity} { line.note ? <span style={{ ...theme.typography.pTiny, marginRight: theme.spacing.xxs }}> ({line.note}) </span> : null }
                                                </span>
                                                <span>
                                                    {line.probability}
                                                </span>
                                            </PackDetailItem>
                                        ))
                                    }
                                </PackDetailsList>
                            </PackDetailsContainer>
                        </div>
                        <div>
                            <Image alt={STRINGS.campaignBox} src={RES.images.box} />
                            <ButtonContainer>
                                <StyledInput
                                    type="number"
                                    max={boxInfo.account_limit}
                                    min={1}
                                    value={boxPurchaseAmount}
                                    onChange={(e) => onAmountChange(e.target.value, setBoxPurchaseAmount, 1, boxInfo.account_limit)}
                                    label={STRINGS.boxesAmount}
                                    infoText={STRINGS.boxPurchaseLimit.replace("{0}", boxInfo.account_limit).replace("{1}", boxInfo.account_limit_cooldown/60)}
                                    style={customInputStyle}
                                />
                                <StyledButton disabled={!isReleased || (boxInfo && boxInfo.available === 0) || boxPurchaseAmount < 1} onClick={showBuyBoxConfirmationModal} >
                                    { getBuyBoxLabel() }
                                </StyledButton>
                                <Prices>{boxPrice}</Prices>
                                <ButtonCaption>
                                    {STRINGS.boxContents}
                                </ButtonCaption>
                                <ButtonCaption>
                                    {`${boxInfo.available} / ${boxInfo.max_claimable}`}
                                </ButtonCaption>
                            </ButtonContainer>
                            <PackDetailsContainer>
                                <PackDetailsTitle>{STRINGS.packProbabilities}</PackDetailsTitle>
                                <PackDetailsList>
                                    {
                                        STRINGS.boxChances.map((line, index) => (
                                            <PackDetailItem key={index}>
                                                <span>
                                                    {line.rarity} { line.note ? <span style={{ ...theme.typography.pTiny, marginRight: theme.spacing.xxs }}> ({line.note}) </span> : null }
                                                </span>
                                                <span>
                                                    {line.probability}
                                                </span>
                                            </PackDetailItem>
                                        ))
                                    }
                                </PackDetailsList>
                            </PackDetailsContainer>
                        </div>
                    </Container>
                    <Container>
                        <Logo alt={STRINGS.campaign} src={RES.images.logo} />
                    </Container>
                    {isReleased ? renderSecondaryMarketLinks() : null}
                </MainContentContainer>
                <StyledConfirmationModal
                    show={showBuyPackConfirmation}
                    onConfirm={onBuyPacks}
                    onClose={() => setShowBuyPackConfirmation(false)}
                    confirmationButtonLabel={packPurchaseAmount === 1 ? STRINGS.buyPack : STRINGS.buyPacks}
                    title={packPurchaseAmount === 1 ? STRINGS.buyPackConfirmation.replace("{0}", packPrice) : STRINGS.buyPacksConfirmation.replace("{0}", packPurchaseAmount).replace("{1}", packPrice)}
                />
                <StyledConfirmationModal
                    show={showBuyBoxConfirmation}
                    onConfirm={onBuyBox}
                    onClose={() => setShowBuyBoxConfirmation(false)}
                    confirmationButtonLabel={boxPurchaseAmount === 1 ? STRINGS.buyBox : STRINGS.buyBoxes}
                    title={boxPurchaseAmount === 1 ? STRINGS.buyBoxConfirmation.replace("{0}", boxPrice) : STRINGS.buyBoxesConfirmation.replace("{0}", boxPurchaseAmount).replace("{1}", boxPrice)}
                />
                <StyledErrorModal show={errorMessage !== ""} onClose={() => setErrorMessage("")} >
                    {errorMessage}
                    { isCPUErrorMessage(errorMessage) ?
                        <CPUErrorMessage>
                            {STRINGS.cpuErrorMessage}
                        </CPUErrorMessage> :
                        ""
                    }
                    {
                        isNETErrorMessage(errorMessage) ?
                            <CPUErrorMessage>
                                {STRINGS.netErrorMessage}
                            </CPUErrorMessage> :
                            ""
                    }
                </StyledErrorModal>
                <StyledSuccessModal
                    show={showBuySuccessMessage}
                    onConfirm={() => setShowBuySuccessMessage(false)}
                    onClose={() => setShowBuySuccessMessage(false)}
                    title={STRINGS.successfulBuy}
                >
                    {STRINGS.buySuccessMessage}
                </StyledSuccessModal>
            </PageSizeContainer>
            :
            <Loading />
    );
}

export default WAX_SIGN_IN? withUAL(BuyPacks) : BuyPacks;
