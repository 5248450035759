import React from 'react';
import StyledLink from '@components/Link';
import { withUAL } from 'ual-reactjs-renderer';
import Dropdown from '@components/Dropdown';
import STRINGS from '@utils/strings';

function LoginButton({ ual }) {
    return (
        !ual.activeUser ?
            <StyledLink header onClick={ual.showModal}>
                {STRINGS.logIn}
            </StyledLink>
            :
            <Dropdown label={ual.activeUser.accountName} options={[{ label: STRINGS.logOut, onClick: ual.logout }]} />
    );
}

export default withUAL(LoginButton);
