import { useEffect, useState } from "react";
import { RELEASE_TEST, RELEASE_DATE } from '@utils/globals';

function IsReleased() {
    if (RELEASE_TEST) {
        return true;
    } else {
        const [ isReleased, setIsReleased ] = useState(false);

        let interval = null;
        const updateRemainingTime = () => {
            if (RELEASE_DATE.getTime() - Date.now() < 0) {
                setIsReleased(true);
                clearInterval(interval);
            }
        };

        useEffect(() => {
            updateRemainingTime();
            interval = setInterval(updateRemainingTime, 1000);
            return () => clearInterval(interval);
        }, [isReleased]);

        return isReleased;
    }
}

export default IsReleased;
