import styled from '@emotion/styled';

const IconLink = styled.a(({ theme }) => ({
    cursor: 'pointer',
    margin: theme.spacing.xxs
}));

function SocialMediaLink({ link, logo, alt }) {
    return (
        <IconLink href={link} target="_blank" rel="noopener noreferrer">
            <img style={{ width: '24px' }} src={logo}  alt={alt} />
        </IconLink>
    );
}

export default SocialMediaLink;