import React from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { isReleased } from '@utils/globals';
import Countdown from '@components/Countdown';
import STRINGS from '@utils/strings';

const Container = styled.div(({ theme, isReleased }) => {
    let releasedStyle = isReleased ? { textTransform: 'uppercase', padding: theme.spacing.xs } : { padding: theme.spacing.xxs };

    return {
        ...theme.typography.h5,
        width: '100%',
        color: theme.colors.primaryLight,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: theme.colors.backgroundColor,
        ...releasedStyle
    };
});

const Column = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing.xs
}));

function Ribbon() {
    const theme = useTheme();

    return (
        <Container isReleased={isReleased()}>
            {
                isReleased() ?
                    STRINGS.ribbon
                    :
                    <Column>
                        <div>{STRINGS.homePage.launchAnnoucement}</div>
                        <Countdown />
                    </Column>
            }
        </Container>
    );
}

export default Ribbon;
