const breakpoints = [ 576, 768, 992, 1366 ];

const theme = {
    colors: {
        primaryLight: '#262626',
        primary: '#141414',
        primaryDark: '#000',
        secondaryLighter: '#757575',
        secondaryLight: '#4D4D4D',
        secondary: '#262626',
        secondaryDark: '#141414',
        tertiary: '#141414;',
        tertiaryLight: '#AEAEAE',
        backgroundColor: '#FFFFFF',
        errorColorDark: '#A91414',
        errorColor: '#CA1515',
        errorColorLight: '#E54C4C',
        warningColorDark: '#A99014',
        warningColor: '#CAAC15',
        warningColorLight: '#FFD569',
        successColorDark: '#05532B',
        successColor: '#1E794B',
        successColorLight: '#64BC90'
    },
    spacing: {
        xxxs: '4px',
        xxs: '8px',
        xs: '16px',
        s: '24px',
        m: '32px',
        l: '40px',
        xl: '62px',
        xxl: '80px',
        xxxl: '106px'
    },
    borderRadius: {
        s: '4px',
        m: '8px',
        l: '16px'
    },
    mediaQuery: {
        mobileOnly: `@media (max-width: ${breakpoints[0] - 1}px)`,
        largeMobileOnly: `@media (max-width: ${breakpoints[1] - 1}px)`,
        largeMobileUp: `@media (min-width: ${breakpoints[0]}px)`,
        tabletUp: `@media (min-width: ${breakpoints[1]}px)`,
        tabletLandscapeUp: `@media (min-width: ${breakpoints[2]}px)`,
        desktopUp: `@media (min-width: ${breakpoints[3]}px)`
    },
    typography: {
        fontFamily: "'Local Font', serif",
        h1: {
            fontSize: '48px',
            lineHeight: '130%',
            fontWeight: 600
        },
        h2: {
            fontSize: '32px',
            lineHeight: '130%',
            fontWeight: 600
        },
        h3: {
            fontSize: '28px',
            lineHeight: '130%',
            fontWeight: 600
        },
        h4: {
            fontSize: '24px',
            lineHeight: '130%',
            fontWeight: 600
        },
        h5: {
            fontSize: '20px',
            lineHeight: '130%',
            fontWeight: 600
        },
        h6: {
            fontSize: '18px',
            lineHeight: '150%',
            fontWeight: 500
        },
        p: {
            fontSize: '18px',
            lineHeight: '160%',
            fontWeight: 400
        },
        pBold: {
            fontSize: '18px',
            lineHeight: '160%',
            fontWeight: 600,
            opacity: 0.9
        },
        pTiny: {
            fontSize: '16px',
            lineHeight: '150%',
            fontWeight: 400,
            opacity: 0.9
        },
        bubblegum: {
            fontFamily: 'Bubblegum',
            fontSize: 32,
            lineHeight: '130%',
            letterSpacing: '2%',
            fontWeight: 300,
            [`@media (max-width: ${breakpoints[1] - 1}px)`]: {
                fontSize: 22
            }
        }
    },
    size: {
        headerHeight: '89.13px',
        footerHeight: '157.19px',
        mintWidth: '60px',
        mintHeight: '25px',
        assetWidth: '300px',
        secondaryMarketLogosWidth: '150px',
        assetWidthTiny: '240px',
        loadingHeight: '100px',
        loadingWidth: '100px',
        loadingBorder: '8px',
        gridItemButtonsWidth: '180px'
    },
    shadow: {
        dropShadow: '5px 5px 30px 2px rgba(64,64,64,0.5)',
        dropShadowGridButtons: '4px 4px 10px 4px #000000'
    },
    transition: {
        default: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)'
    }
};

export default theme;
