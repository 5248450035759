import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import ComicReader from '@components/ComicReader';
import { getInventory, getCardIdFromAsset, getTemplateIdFromAsset } from '@api/api';
import { withUAL } from 'ual-reactjs-renderer';
import { IPFS_ENDPOINT } from '@utils/globals';
import { KEY_ASSETS } from '@utils/comicTemplates';
import RES from '@utils/resources';
import StyledButton from '@components/Button';
import STRINGS from '../utils/strings';
import StyledModal from '@components/Modal';

const Container = styled.div(({ theme }) => ({
    color: theme.colors.secondaryDark,
    maxWidth: '80ch',
    margin: 'auto',
    padding: `0 ${theme.spacing.xs} ${theme.spacing.xxl}`
}));

const SelectComic = styled.h3(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    padding: theme.spacing.l
}));

const ButtonsContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.xxs
}));

const Cover = styled.img(({ enableComicBook }) => ({
    width: '180px',
    cursor: enableComicBook ? 'pointer' : 'not-allowed'
}));

const ComicContainer = styled.div(({ theme, enableComicBook }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: theme.spacing.xs,
    alignItems: 'center',
    filter: enableComicBook ? 'grayscale(0)' : 'grayscale(1)',
    [theme.mediaQuery.mobileOnly]: {
        flexDirection: 'column'
    }
}));

const Title = styled.div(({ theme }) => ({
    ...theme.typography.h4,
    marginBottom: theme.spacing.m,
    display: 'flex',
    justifyContent: 'center'
}));

function Reader({ ual }) {
    const [ assets, setAssets ] = useState([]);
    const [ comicBook, setComicBook ] = useState([]);
    const [ showComicBook, setShowComicBook ] = useState(false);
    const [ comicBookTitle, setComicBookTitle ] = useState('');
    const [ enableComicBook, setEnableComicBook ] = useState(false);
    const [ enableAhaMoment, setEnableAhaMoment ] = useState(false);

    useEffect(() => {
        loadInventory();
    }, []);

    useEffect(() => {
        verifyInventoryAssets();
    }, [assets]);

    function loadInventory() {
        if (ual.activeUser) {
            getInventory(ual.activeUser.accountName, (assets) => { setAssets(assets); });
        }
    }

    function verifyInventoryAssets() {
        let ahaMomentEnablers = KEY_ASSETS.ahaMomentEnablers;
        assets.forEach(asset => {
            if (enableAhaMoment && enableComicBook) {
                return;
            }

            const templateId = getTemplateIdFromAsset(asset);
            const cardId = getCardIdFromAsset(asset);

            if (!enableComicBook && KEY_ASSETS.comicBookEnablers.includes(templateId)) {
                setEnableComicBook(true);
            }

            // Checks if specified cardID hasn't been found yet
            if (ahaMomentEnablers.has(cardId)) {
                let inkAndColourTemplateId = ahaMomentEnablers.get(cardId);
                // If templateID is a match, remove template ID from map
                if (inkAndColourTemplateId.includes(templateId)) {
                    ahaMomentEnablers.delete(cardId);
                }
            }

            // If map is empty, inventory contains all 21 cards
            if (ahaMomentEnablers.size === 0) {
                setEnableAhaMoment(true);
            }
        });
    }
    const comics = RES.comicBook;

    function createComicBook(comicBook) {
        let comicAssets = [];

        comicBook.vol.map(comic => {
            for (const key in comic) {
                comicAssets.push({
                    srcImg: `${IPFS_ENDPOINT}${comic[key]}`,
                    title: comicBook.title
                });
            }
        });

        if (enableAhaMoment) {
            comicBook.ahaMoment.forEach((ipfsHash) => {
                comicAssets.splice(comicAssets.length - 1, 0, {
                    srcImg: `${IPFS_ENDPOINT}${ipfsHash}`,
                    title: comicBook.title
                });
            });
        }

        setComicBookTitle(comicBook.title);
        setComicBook(comicAssets);
    }

    function selectComic(comic) {
        createComicBook(comic);
        setShowComicBook(true);
    }

    const modalStyle = {
        width: '100vw',
        overflowY: 'scroll',
        margin: 'initial'
    };

    return (
        <Container>
            { enableComicBook ? <SelectComic>{STRINGS.selectComicBook}</SelectComic> : <SelectComic>{STRINGS.noComicsAvailable}</SelectComic> }
            <ComicContainer enableComicBook={enableComicBook}>
                { comics.map((comic, index) =>
                    (<ButtonsContainer key={index}>
                        <Cover
                            src={comic.cover}
                            onClick={enableComicBook ? () => selectComic(comic) : null}
                            enableComicBook={enableComicBook}
                        />
                        <StyledButton
                            onClick={() => selectComic(comic)}
                            disabled={!enableComicBook}
                        >
                            {comic.button}
                        </StyledButton>
                    </ButtonsContainer>)
                )}
            </ComicContainer>
            <StyledModal
                dark
                show={showComicBook}
                onClose={() => setShowComicBook(false)}
                style={modalStyle}
            >
                { comicBook && comicBookTitle ? (
                    <>
                        <Title>{comicBookTitle}</Title>
                        <ComicReader items={comicBook}  />
                    </>
                ) : null }
            </StyledModal>
        </Container>
    );
}

export default withUAL(Reader);
