import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import StyledModal from '@components/Modal';
import STRINGS from '@utils/strings';
import HubspotForm from 'react-hubspot-form';
import Loading from '@components/Loading';

const Title = styled.div(({ theme }) => ({
    ...theme.typography.h6,
    marginBottom: theme.spacing.m,
    display: 'flex',
    justifyContent: 'center',
    color: theme.colors.tertiary
}));

const Help = styled.div(({ theme }) => ({
    ...theme.typography.p,
    marginTop: theme.spacing.s,
    color: theme.colors.tertiaryLight,
    'a': {
        color: theme.colors.tertiaryLight
    },
    'a:hover': {
        color: theme.colors.tertiary01,
        transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)'
    }
}));

function validateForm() {
    const waxWalletAddress = document.querySelector('input[name="wax_wallet_address"]');

    document.querySelector('input[type="submit"]').addEventListener('click', (event) => {
        let formValidated = false;

        const results = [
            waxWalletAddress ? { element: waxWalletAddress, isValid: isValidWallet(waxWalletAddress.value), message: 'Invalid Wax wallet address' } : null
        ];

        for (const item of results) {
            const error = item.element.parentElement.querySelector('span') ? true : false;

            if (!item.isValid && !error) {
                const notification = document.createElement('span');

                item.element.style.marginBottom = '8px';
                item.element.classList.add('modalError');
                notification.classList.add('modalErrorMsg');
                notification.style.display = 'block';
                notification.textContent = item.message;
                item.element.insertAdjacentElement('afterend', notification);

                item.element.addEventListener('keyup', () => {
                    notification.remove();
                    item.element.classList.remove('modalError');
                    item.element.removeAttribute('style');
                });
            } else {
                formValidated = true;
            }
        }

        if (!formValidated) {
            event.preventDefault();
        }

    });
}

function isValidWallet(wallet) {
    return wallet === '' || !/[^a-zA-Z1-5.]/.test(wallet) && wallet.length <= 12 && !wallet.endsWith('.') ? true : false;
}

function StyledFormModal({ show, onConfirm, onClose, title, style, portalId, formId, help, ...otherProps }) {
    const theme = useTheme();

    const hbspotStyle = {
        position: 'relative',
        maxHeight: '100vh !important',
        overflowY: 'scroll !important',

        '.modalForm': {
            display: 'flex !important',
            flexDirection: 'column !important',
            alignItems: 'center !important',
            marginTop: `24px !important`,
            minHeight: '250px !important'
        },

        '.modalForm p': {
            color: '#141414 !important',
            fontSize: '0.8rem !important',
            fontWeight: '300 !important',
            lineHeight: '150% !important',
            textAlign: 'left'
        },

        '.modalForm label': {
            marginBottom: 0,
            display: 'block !important',
            width: '100% !important',
            textAlign: 'left !important',
            fontWeight: 400,
            color: '#141414'
        },

        '.modalForm .field': {
            marginBottom: '1rem !important'
        },

        '.modalForm input': {
            border: '1px solid #AEAEAE !important',
            backgroundColor: 'transparent !important',
            borderRadius: '3px !important',
            fontSize: '1rem !important',
            padding: '5px !important',
            color: '#141414 !important',
            width: '250px !important',
            outline: 'none !important',
            transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1) !important'
        },

        '.modalForm input::placeholder': {
            color: '#4D4D4D !important',
            opacity: '0.5 !important'
        },

        '.modalForm input:active': {
            border: '1px solid #000 !important'
        },
        '.modalForm input:focus': {
            border: '1px solid #000 !important'
        },
        '.modalForm ul': {
            listStyle: 'none',
            paddingLeft: 0
        },
        '.modalForm li label': {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'
        },
        '.modalForm li label input': {
            width: '20px !important',
            marginRight: 5
        },

        'input.modalButton': {
            transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1) !important',
            transitionDuration: '150ms !important',
            color: '#FFF !important',
            backgroundColor: '#141414 !important',
            padding: '8px 24px !important',
            outline: 'none !important',
            fontWeight: '400 !important',
            fontSize: '18px !important',
            lineHeight: '160% !important',
            borderRadius: '0.25rem !important',
            cursor: 'pointer !important',
            marginBottom: '1rem !important',
            border: 'none !important'
        },

        'input.modalButton:hover': {
            backgroundColor: '#262626 !important',
            color: '#FFF !important'
        },

        'input.modalError': {
            border: '1px solid #CA1515 !important'
        },

        '.modalErrorMsg': {
            fontFamily: '"Roboto", sans-serif !important',
            listStyle: 'none !important',
            color: '#CA1515 !important',
            fontWeight: 500,
            fontSize: '1rem !important',
            marginTop: '0 !important',
            paddingLeft: '0 !important'
        }
    };

    return (<>
        <StyledModal show={show} onClose={onClose} style={hbspotStyle}>
            <Title>{title}</Title>
            <HubspotForm
                portalId={portalId}
                formId={formId}
                onSubmit={() => { return (<p>{STRINGS.formSuccessMessage}</p>);}}
                loading={<Loading />}
                cssClass="modalForm"
                errorMessageClass="modalErrorMsg"
                errorClass="modalError"
                submitButtonClass="modalButton"
                onReady={validateForm}
            />
            { help ? <Help>{help}</Help> : null }
        </StyledModal>
    </>);
}

export default StyledFormModal;
