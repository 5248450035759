import * as GLOBAL_VARS from '../utils/globals';
import * as waxjs from '@waxio/waxjs/dist';
import { Serialize } from 'eosjs';

const wax = new waxjs.WaxJS({ rpcEndpoint: GLOBAL_VARS.WAX_RPC_ENDPOINT });

const { ExplorerApi } = require("atomicassets");

const DELPHIORACLE_CONTRACT_ACCOUNT = 'delphioracle';
const DELPHIORACLE_WAXPUSD_PAIR = 'waxpusd';
const DELPHIORACLE_DATAPOINTS_TABLE = 'datapoints';
const DELPHIORACLE_KEY_TYPE = 'i64';

export async function fetchWAXUSDMedianPrice() {
    const resp = await wax.rpc.get_table_rows({
        limit: 1,
        code: DELPHIORACLE_CONTRACT_ACCOUNT,
        scope: DELPHIORACLE_WAXPUSD_PAIR,
        table: DELPHIORACLE_DATAPOINTS_TABLE,
        json: true,
        index_position: 3,
        key_type: DELPHIORACLE_KEY_TYPE,
        reverse: true
    });
    if (resp.rows) {
        return resp.rows[0].median;
    }
}

function calculateWAXPrice(formattedUSDPrice, intendedDelphiMedian, claimAmount) {
    return ((formattedUSDPrice / (intendedDelphiMedian / 10000)) * claimAmount).toFixed(8);
}

// transfers the asset to the pack opener account
// Asset must be in the user's collection, user must have enough resources to call the transfer action
export async function transferAsset(activeUser, assetId, packOpened = () => {}, error = () => {}) {
    try {
        const response = await activeUser.signTransaction(
            {
                actions: [
                    {
                        account: GLOBAL_VARS.BOOST_ACCOUNT,
                        name: GLOBAL_VARS.BOOST_ACTION,
                        authorization: [{
                            actor: activeUser.accountName,
                            permission: activeUser.requestPermission
                        }],
                        data: {}
                    },
                    {
                        account: GLOBAL_VARS.ATOMICASSETS_ACCOUNT,
                        name: GLOBAL_VARS.ATOMICASSETS_TRANSFER_ACTION,
                        authorization: [{
                            actor: activeUser.accountName,
                            permission: activeUser.requestPermission
                        }],
                        data: {
                            from: activeUser.accountName,
                            to: GLOBAL_VARS.PACK_OPENER_ACCOUNT,
                            asset_ids: [assetId],
                            memo: GLOBAL_VARS.MEMOS.UNBOX
                        }
                    }
                ]
            },
            {
                blocksBehind: 3,
                expireSeconds: 30
            }
        );

        packOpened(response);
        return;

    } catch (e) {
        console.debug(e);
        error(e.message);
        return;
    }
}

//Asset is the asset object of the atomic hub api
export function getTemplateIdFromAsset(asset) {
    return asset.template.template_id;
}

export function getCardIdFromAsset(asset) {
    return asset.data.cardid ? asset.data.cardid.toString() : null;
}

//Asset is the asset object of the atomic hub api
export function getOpenedFromAsset(asset) {
    return asset.mutable_data.opened;
}

//Asset is the asset object of the atomic hub api
export function getAssetId(asset) {
    return asset.asset_id;
}

//Asset is the asset object of the atomic hub api
export function getAssetMintNumber(asset) {
    return asset.template_mint;
}

export function getSchemaFromAsset(asset) {
    return asset.schema.schema_name;
}

export async function getInventory(accountName, updateInventory = () => {}, error = () => {}) {
    try {
        fetch(`${GLOBAL_VARS.AA_ENDPOINT}/atomicassets/v1/assets?owner=${accountName}&collection_name=${GLOBAL_VARS.COLLECTION_NAME}&page=1&limit=1000&order=desc&sort=transferred_at_time`)
            .then(res => res.json())
            .then(result => {
                if (result.data && (result.data.length > 0)) {
                    updateInventory(result.data);
                } else {
                    console.debug('Inventory empty.');
                    updateInventory([]);
                }
            });
    } catch (e) {
        console.error(e);
        error(e.message);
    }
}

export async function getDropInfo(drop_id, updateDropInfo = () => {}, error = () => {}) {
    try {
        const resp = await wax.rpc.get_table_rows({
            limit: 1000,
            code: GLOBAL_VARS.ATOMICDROPS_ACCOUNT,
            scope: GLOBAL_VARS.ATOMICDROPS_ACCOUNT,
            table: GLOBAL_VARS.ATOMICDROPS_TABLE,
            lower_bound: drop_id,
            upper_bound: drop_id,
            json: true
        });

        let intendedDelphiMedian = await fetchWAXUSDMedianPrice();
        let salesData = [];

        const drop = resp.rows.filter((drop) => {
            //   change it to a constant.
            return drop.drop_id === drop_id;
        });

        if (drop.length > 0) {
            salesData[0] = drop[0];
            salesData[0].formattedUSDPrice = parseFloat(salesData[0].listing_price);
            salesData[0].formattedWAXPrice = calculateWAXPrice(salesData[0].formattedUSDPrice, intendedDelphiMedian, 1);
            salesData[0].available = salesData[0].max_claimable - salesData[0].current_claimed;
            // salesData[0].unlocked = GLOBAL_VARS.PACK_UNLOCKED;
            salesData[0].total = salesData[0].current_claimed;
        }
        updateDropInfo(salesData[0]);
    } catch (e) {
        console.error(e);
        error(e.message);
    }
}

// read from AA API the asset id images and data
// Returns a list of promises, that when fullfilled will be a list of asset data
export function getAssetsData(assetIdList) {
    const explorerApi = new ExplorerApi(GLOBAL_VARS.AA_ENDPOINT, GLOBAL_VARS.ATOMICASSETS_ACCOUNT, { fetch });
    return assetIdList.map((id) => {
        return explorerApi.getAsset(id);
    });
}

export async function buyPacks(activeUser, drop, claimAmount, packBought = () => {}, error = () => {}) {
    let intendedDelphiMedian = await fetchWAXUSDMedianPrice();
    let waxPrice = calculateWAXPrice(drop.formattedUSDPrice, intendedDelphiMedian, claimAmount);

    let formattedPrice = waxPrice + ' WAX';

    try {
        const response = await activeUser.signTransaction(
            {
                actions: [
                    {
                        account: GLOBAL_VARS.BOOST_ACCOUNT,
                        name: GLOBAL_VARS.BOOST_ACTION,
                        authorization: [{
                            actor: activeUser.accountName,
                            permission: activeUser.requestPermission
                        }],
                        data: {}
                    },
                    {
                        account: GLOBAL_VARS.EOSIO_ACCOUNT,
                        name: GLOBAL_VARS.ATOMICASSETS_TRANSFER_ACTION,
                        authorization: [{
                            actor: activeUser.accountName,
                            permission: activeUser.requestPermission
                        }],
                        data: {
                            from: activeUser.accountName,
                            to: GLOBAL_VARS.ATOMICDROPS_ACCOUNT,
                            quantity: formattedPrice,
                            // TODO: Review memo for other collections
                            memo: GLOBAL_VARS.MEMOS.DEPOSIT
                        }
                    },
                    {
                        account: GLOBAL_VARS.ATOMICDROPS_ACCOUNT,
                        name: GLOBAL_VARS.ATOMICDROPS_CLAIM_ACTION,
                        authorization: [{
                            actor: activeUser.accountName,
                            permission: activeUser.requestPermission
                        }],
                        data: {
                            claim_amount: claimAmount,
                            claimer: activeUser.accountName,
                            drop_id: drop.drop_id,
                            country: '',
                            intended_delphi_median: intendedDelphiMedian,
                            referrer: GLOBAL_VARS.COLLECTION_NAME
                        }
                    }
                ]
            },
            {
                blocksBehind: 3,
                expireSeconds: 30
            });

        packBought(response);
        return;
    } catch (e) {
        console.debug(e);
        error(e.message);
        return;
    }
}

export function getBounds(accountName, startTimePoint) {
    const sb = new Serialize.SerialBuffer({
        textEncoder: new TextEncoder(),
        textDecoder: new TextDecoder()
    });
    sb.pushName(accountName);

    let reversedArray = new Uint8Array(16);

    reversedArray.set(sb.array.slice(0, 8).reverse());

    for (let index = 15; index >= 8; index--) {
        reversedArray.set([startTimePoint % 256], index);
        startTimePoint = startTimePoint / 256;
        startTimePoint = Math.floor(startTimePoint);
    }

    const lowerHexIndex = Buffer.from(reversedArray).toString('hex');

    let lower_bound = '0x' + lowerHexIndex;

    for (let i=8; i<16; i++) {
        reversedArray.set([0xff], i);
    }

    const upperHexIndex = Buffer.from(reversedArray).toString('hex');

    let upper_bound = '0x' + upperHexIndex;

    return {
        lower_bound: lower_bound,
        upper_bound: upper_bound
    };
}

// startTimePoint is UNIX time in micro seconds
export async function getHistory(accountName, startTimePoint, setPackRips = () => {}) {
    let { lower_bound, upper_bound } = getBounds(accountName, startTimePoint);

    let more = false;
    let packRipsArray = [];

    let resp = {};
    try {
        do {
            resp = await wax.rpc.get_table_rows({
                code: GLOBAL_VARS.PACK_OPENER_ACCOUNT,
                scope: GLOBAL_VARS.PACK_OPENER_ACCOUNT,
                table: GLOBAL_VARS.PACK_OPENER_PACKSTATUS_TABLE,
                json: true,
                key_type: 'i128',
                index_position: 3,
                lower_bound: lower_bound,
                upper_bound: upper_bound,
                limit: 1000
            });

            packRipsArray = [ ...packRipsArray, ...resp.rows ];

            more = resp.more;
            lower_bound = resp.next_key;

        } while (more);

    } catch (e) {
        console.error(e.message);
    }

    // Only consider opened packs (step === 4)
    packRipsArray = packRipsArray.filter(packRip => {
        return packRip.step === 4;
    });

    let noDuplicatesObject = {};

    // in case of duplicates, they get overwritten
    packRipsArray.forEach(packRip => {
        noDuplicatesObject[packRip.pack_asset_id] = packRip;
    });

    let noDuplicatesList = [];

    Object.keys(noDuplicatesObject).forEach(key => {
        noDuplicatesList.push(noDuplicatesObject[key]);
    });

    let orderedList = noDuplicatesList.sort((p1, p2) => {
        let time1 = Date.parse(p1.unbox_time);
        let time2 = Date.parse(p2.unbox_time);
        return time2 - time1;
    });

    //Only get last 50 for now
    //Todo return everything and only getAssetsData from 20 a time.
    let limitedList = orderedList.slice(0, 50);

    setPackRips(limitedList);
}

export async function getScoreboard(scoreboardBuilt) {
    let lower_bound = 0;
    let more = false;
    let scoreboardList = [];

    let resp = {};
    do {
        resp = await wax.rpc.get_table_rows({
            code: GLOBAL_VARS.SCOREBOARD_ACCOUNT,
            scope: GLOBAL_VARS.SCOREBOARD_ACCOUNT,
            table: GLOBAL_VARS.SCOREBOARD_SCORES_TABLE,
            lower_bound: lower_bound,
            json: true,
            limit: 1000
        });

        scoreboardList = [ ...scoreboardList, ...resp.rows ];

        more = resp.more;
        lower_bound = resp.next_key;

    } while (more);

    scoreboardBuilt(scoreboardList);
}

export async function burnAsset(activeUser, assetId, assetBurnt = () => {}, error = () => {}) {
    try {
        const response = await activeUser.signTransaction(
            {
                actions: [
                    {
                        account: GLOBAL_VARS.ATOMICASSETS_ACCOUNT,
                        name: GLOBAL_VARS.ATOMICASSETS_BURN_ACTION,
                        authorization: [{
                            actor: activeUser.accountName,
                            permission: activeUser.requestPermission
                        }],
                        data: {
                            asset_owner: activeUser.accountName,
                            asset_id: assetId
                        }
                    }
                ]
            },
            {
                blocksBehind: 3,
                expireSeconds: 30
            }
        );

        assetBurnt(response);
        return;

    } catch (e) {
        console.debug(e);
        error(e.message);
        return;
    }
}
