import { useEffect, useRef, useState } from "react";
import StyledLink from '@components/Link';
import styled from '@emotion/styled';

const DropdownContainer = styled.div(() => ({
    position: 'relative'
}));

const DropdownList = styled.div(({ theme }) => ({
    position: 'absolute',
    top: '34px',
    right: 0,
    display: 'grid',
    color: theme.colors.primary,
    backgroundColor: theme.colors.backgroundColor,
    padding: `${theme.spacing.xxs} ${theme.spacing.xs}`,
    borderRadius: theme.borderRadius.s,
    boxShadow: theme.shadow.dropShadow,
    transition: theme.transition.default
}));

const DropdownItem = styled.div(({ theme }) => ({
    ...theme.typography.p,
    '&:hover': {
        ...theme.typography.pBold,
        color: `${theme.colors.primaryDark}`,
        cursor: 'pointer'
    }
}));

function Dropdown({ label, options = [] }) {
    const containerRef = useRef(null);

    const [ open, setOpen ] = useState(false);

    const handleClickOutside = (event) => {
        if (containerRef && !containerRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const optionsList = options.map((option, index) => <DropdownItem key={index} onClick={option.onClick}>{option.label}</DropdownItem>);

    return (
        <DropdownContainer ref={containerRef}>
            <StyledLink header onClick={() => setOpen(prevState => !prevState)}>
                {label}
            </StyledLink>
            {
                open ?
                    <DropdownList>
                        {optionsList}
                    </DropdownList> : null
            }
        </DropdownContainer>
    );
}

export default Dropdown;