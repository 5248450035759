import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { useEffect, useState } from 'react';

import Chevron from '@images/chevron-down.svg';

const ScrollContainer = styled.div(({ theme }) => ({
    color: theme.colors.primary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: theme.spacing.xxs,
    right: '5%',
    position: 'absolute',
    bottom: '5vh',
    animation: `${bounce} 2s infinite`,
    transition: 'opacity 3s ease-in-out'
}));

const bounce = keyframes`
    50% {
        transform: translate3d(0, -24px, 0);
    }
`;

function Scroll() {
    const [ visible, setVisible ] = useState(true);

    useEffect(() => {
        window.onscroll = () => {
            const scrollComponent = document.getElementById('scroll');
            if (scrollComponent) {
                scrollComponent.style.opacity = '0';
            }
            setVisible(false);
        };
    }, []);

    return (
        <ScrollContainer id="scroll">
            <img src={Chevron} alt="chevron-down" />
            <span>scroll down</span>
        </ScrollContainer>
    );
}

export default Scroll;