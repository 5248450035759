import styled from '@emotion/styled';
import { isWindows } from 'react-device-detect';

const Button = styled.button(({ secondary, theme, style }) => ({
    ...theme.typography.p,
    color: secondary ? theme.colors.primary : theme.colors.backgroundColor,
    backgroundColor: secondary ? theme.colors.backgroundColor : theme.colors.primary,
    padding: `${theme.spacing.xxs} ${theme.spacing.s}`,
    border: 'none',
    borderRadius: theme.borderRadius.s,
    opacity: 1,
    transition: theme.transition.default,
    '&:hover': {
        backgroundColor: secondary ? theme.colors.secondaryLight : theme.colors.primaryLight,
        color: theme.colors.backgroundColor,
        cursor: 'pointer'
    },
    '&:disabled': {
        // color: theme.colors.secondaryDark,
        '&:hover': {
            color: secondary ? theme.colors.primary : theme.colors.backgroundColor,
            backgroundColor: secondary ? theme.colors.backgroundColor : theme.colors.primary
        },
        // backgroundColor: theme.colors.primaryDark,
        cursor: 'not-allowed'
    },
    'span': {
        verticalAlign: isWindows ? 'middle' :'sub'
    },
    ...style
}));

function StyledButton({ children, primary, secondary, text, style={}, ...otherProps }) {
    return <Button secondary={secondary} style={style} {...otherProps}><span>{children}</span></Button>;
}

export default StyledButton;
