import React, { useState } from 'react';
import { useTheme } from '@emotion/react';
import { useHistory } from 'react-router';
import styled from '@emotion/styled';
import StyledButton from '@components/Button';
import Countdown from '@components/Countdown';
import StyledFormModal from '@components/FormModal';
import SocialMediaLink from '@components/SocialMediaLink';
import STRINGS from '@utils/strings';
import { isReleased, SHOW_HEADER, SHOW_BUY_PAGE } from '@utils/globals';
import RES from '@utils/resources';
import ROUTES from '@utils/routes';
import Scroll from '@components/Scroll';
import { isMobileOnly } from 'react-device-detect';

import TelegramLogo from '@images/telegram-icon-black.svg';
import TwitterLogo from '@images/twitter-icon-black.svg';
import DiscordLogo from '@images/discord-black.svg';

const CenterRowContainer = styled.div(() => ({
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center'
}));

const CenterColContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing.s
}));

const PageSizeContainer = styled.div(({ theme }) => ({
    minHeight: SHOW_HEADER ? `calc(100vh - ${theme.size.headerHeight})` : '100vh',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage: `url(${RES.images.backgroundSmall})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    justifyContent: 'center',
    [ theme.mediaQuery.tabletUp ]: {
        backgroundImage: `url(${RES.images.backgroundLarge})`
    }
}));

const HeadlineContentContainer = styled.div(({ theme }) => ({
    backgroundColor: theme.colors.backgroundColor,
    padding: theme.spacing.xs,
    borderRadius: theme.borderRadius.l,
    position: 'relative',
    margin: '16px',
    [ theme.mediaQuery.tabletUp ]: {
        position: 'absolute',
        padding: theme.spacing.l
    },
    [ theme.mediaQuery.mobileOnly ]: {
        width: '100%'
    }
}));

const HeroContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing.s,
    gap: theme.spacing.xs,
    maxWidth: '90ch',
    'div': {
        width: '100%'
    },
    [theme.mediaQuery.tabletLandscapeUp]: {
        flexDirection: 'row',
        'div': {
            maxWidth: '50%',
            height: '100%',
            'img': {
                height: '25vh'
            },
            'h2': {
                textAlign: 'left'
            },
            'p': {
                textAlign: 'left'
            }
        }
    },
    [theme.mediaQuery.desktopUp]: {
        flexDirection: 'column',
        'div': {
            maxWidth: '100%',
            height: '100%',
            'img': {
                height: '25vh'
            },
            'h2': {
                textAlign: 'center'
            },
            'p': {
                textAlign: 'center'
            }
        }
    }
}));

const LogoImageContainer = styled.div(({ theme }) => ({
    objectFit: 'contain',
    height: '10vh',
    maxWidth: 350,
    overflow: 'hidden',
    [theme.mediaQuery.landscapeTabletUp]: {
        height: '20vh'
    }
}));

const LogoImage = styled.img(({ theme }) => ({
    objectFit: 'contain',
    objectPosition: 'top',
    height: '120%',
    [ theme.mediaQuery.largeMobileUp ]: {
        height: '100%'
    }
}));

const Headline = styled.h2(({ theme }) => ({
    ...theme.typography.h2,
    color: theme.colors.tertiary,
    textAlign: 'center',
    margin: `${theme.spacing.xs} ${theme.spacing.xxs} `
}));

const Subtext = styled.p(({ theme }) => ({
    ...theme.typography.p,
    maxWidth: '65ch',
    color: theme.colors.tertiary,
    textAlign: 'center',
    margin: `0 ${theme.spacing.xxs} ${theme.spacing.xs}`,
    [ theme.mediaQuery.desktopUp ]: {
        margin: `0 0 ${theme.spacing.xs}`
    }
}));

const ButtonsContainer = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing.xs,
    [ theme.mediaQuery.largeMobileOnly ]: {
        flexDirection: 'column'
    }
}));

const LaunchAnnouncement = styled.p(({ theme }) => ({
    ...theme.typography.pBold,
    maxWidth: '65ch',
    color: theme.colors.tertiary,
    textAlign: 'center',
    marginBottom: theme.spacing.xxs
}));

const ReleaseDate = styled.h4(({ theme }) => ({
    ...theme.typography.h4,
    maxWidth: '65ch',
    color: theme.colors.tertiary,
    textAlign: 'center',
    marginTop: theme.spacing.l,
    marginBottom: 0
}));

const ReleaseTime = styled.p(({ theme }) => ({
    ...theme.typography.p,
    maxWidth: '65ch',
    color: theme.colors.tertiary,
    textAlign: 'center',
    marginBottom: 0
}));

const Link = styled.a(({ theme }) => ({
    ...theme.typography.p,
    textAlign: 'center',
    color: theme.colors.secondaryDark,
    maxWidth: '50vw',
    marginTop: theme.spacing.s
}));

function BackgroundImageLayout() {
    const theme = useTheme();
    const history = useHistory();

    const [ showFormModal, setShowFormModal ] = useState(false);

    return (
        <>
            <PageSizeContainer>
                <HeadlineContentContainer>
                    <HeroContainer>
                        <LogoImageContainer>
                            <LogoImage alt={STRINGS.campaign} src={RES.images.logo} />
                        </LogoImageContainer>
                        <div>
                            <Headline>
                                {STRINGS.homePage.headline}
                            </Headline>
                            <Subtext>
                                {STRINGS.homePage.subtext}
                            </Subtext>
                        </div>
                    </HeroContainer>
                    { isReleased() ? null
                        : <CenterColContainer>
                            <LaunchAnnouncement>
                                {STRINGS.homePage.launchAnnoucement}
                            </LaunchAnnouncement>
                            <Countdown />
                        </CenterColContainer>
                    }
                    <CenterRowContainer>
                        <SocialMediaLink
                            link={RES.externalLinks.telegram.link}
                            logo={TelegramLogo}
                            alt={RES.externalLinks.telegram.label}
                        />
                        <SocialMediaLink
                            link={RES.externalLinks.twitter.link}
                            logo={TwitterLogo}
                            alt={RES.externalLinks.twitter.label}
                        />
                        <SocialMediaLink
                            link={RES.externalLinks.discord.link}
                            logo={DiscordLogo}
                            alt={RES.externalLinks.discord.label}
                        />
                    </CenterRowContainer>
                    <CenterRowContainer>
                        <Link href={RES.externalLinks.finney.link} alt={RES.externalLinks.finney.label} target="_blank">{STRINGS.websiteLink}</Link>
                    </CenterRowContainer>
                    { !isMobileOnly ? <Scroll /> : null }
                </HeadlineContentContainer>
            </PageSizeContainer>
            <StyledFormModal
                show={showFormModal}
                onClose={() => setShowFormModal(false)}
                title={STRINGS.formTitle}
                portalId='19612576'
                formId='381de9d0-d4b8-4f8a-96f0-8ed9bec0fdc4'
            />
        </>
    );
}

export default BackgroundImageLayout;
