import { css, Global, useTheme } from '@emotion/react';

function GlobalStyles() {
    const theme = useTheme();

    return (
        <Global
            styles={css`
                html {
                }

                @font-face {
                    font-family: "Local Font";
                    font-weight: 400;
                    src: url("./fonts/ITCAvantGardeStdMd.woff");
                }
                @font-face {
                    font-family: "Local Font";
                    font-weight: 600;
                    src: url("./fonts/ITCAvantGardeStdDemi.woff");
                }

                * {
                    box-sizing: border-box;
                    margin: 0;
                    font-family: ${theme.typography.fontFamily};

                    ::-webkit-scrollbar {
                        width: 10px;
                    }

                    ::-webkit-scrollbar-track {
                        background: #FFFFFF;
                    }

                    ::-webkit-scrollbar-corner {
                        background: #FFFFFF;
                    }

                    ::-webkit-scrollbar-thumb {
                        background: ${theme.colors.primary}DD;
                    }

                    ::-webkit-scrollbar-thumb:hover {
                        background: ${theme.colors.primary}FF;
                    }
                }

                body {
                    background-color: #FFFFFF;
                }

                main {
                    display: block;
                }

                hr {
                }

                pre {
                }

                a {
                    background-color: transparent;
                }

                abbr[title] {
                }

                b,
                strong {
                    font-weight: bolder;
                }

                code,
                kbd,
                samp {
                }

                small {
                    font-size: 80%;
                }

                sub,
                sup {
                    font-size: 75%;
                    line-height: 0;
                    position: relative;
                    vertical-align: baseline;
                }

                sub {
                    bottom: -0.25em;
                }

                sup {
                    top: -0.5em;
                }

                img {
                    border-style: none;
                }

                button,
                input,
                optgroup,
                select,
                textarea {
                }

                button,
                    overflow: visible;
                }

                button,
                    text-transform: none;
                }

                button,
                [type="button"],
                [type="reset"],
                [type="submit"] {
                    -webkit-appearance: button;
                }

                button::-moz-focus-inner,
                [type="button"]::-moz-focus-inner,
                [type="reset"]::-moz-focus-inner,
                [type="submit"]::-moz-focus-inner {
                    border-style: none;
                    padding: 0;
                }

                button:-moz-focusring,
                [type="button"]:-moz-focusring,
                [type="reset"]:-moz-focusring,
                [type="submit"]:-moz-focusring {
                    outline: none;
                }

                fieldset {
                    padding: 0.35em 0.75em 0.625em;
                }

                legend {
                }

                progress {
                    vertical-align: baseline;
                }

                textarea {
                    overflow: auto;
                }

                [type="checkbox"],
                [type="radio"] {
                }

                [type="number"]::-webkit-inner-spin-button,
                [type="number"]::-webkit-outer-spin-button {
                    height: auto;
                }

                [type="search"] {
                }

                [type="search"]::-webkit-search-decoration {
                    -webkit-appearance: none;
                }

                ::-webkit-file-upload-button {
                }

                details {
                    display: block;
                }

                summary {
                    display: list-item;
                }

                template {
                    display: none;
                }

                [hidden] {
                    display: none;
                }
            `}
        />
    );
}

export default GlobalStyles;
