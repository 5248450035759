import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import Grid from '@components/Grid';
import RES from '@utils/resources';
import STRINGS from '@utils/strings';
import { IPFS_ENDPOINT } from '../../utils/globals';

const Header = styled.h3(({ theme }) => ({
    ...theme.typography.h3,
    fontWeight: '700',
    marginTop: theme.spacing.l,
    marginBottom: theme.spacing.s,
    textTransform: 'uppercase',
    textAlign: 'center',
    color: theme.colors.secondaryDark
}));

const Description = styled.p(({ theme }) => ({
    ...theme.typography.p,
    textAlign: 'center',
    color: theme.colors.secondaryDark,
    maxWidth: '60ch'
}));

function Rarities() {
    const theme = useTheme();

    const [ rarities, setRarities ] = useState([]);

    const CustomGrid = {
        [theme.mediaQuery.mobileOnly]: {
            gridTemplateColumns: '1fr',
            'img, video': {
                width: '100%',
                height: '400px'
            }
        },
        [theme.mediaQuery.largeMobileUp]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
            'img, video': {
                width: '100%',
                height: '450px'
            }
        },
        [theme.mediaQuery.tabletLandscapeUp]: {
            marginBottom: theme.spacing.m,
            gridTemplateColumns: 'repeat(2, 1fr)',
            'img, video': {
                width: '100%',
                height: '500px'
            }
        },
        [theme.mediaQuery.desktopUp]: {
            gridTemplateColumns: 'repeat(2, 1fr)'
        }
    };

    useEffect(() => {
        const raritiesList = RES.samples;
        raritiesList.forEach((item) => {
            item.srcImg = item.srcImg.includes(IPFS_ENDPOINT) ? item.srcImg : IPFS_ENDPOINT + item.srcImg;
        });
        setRarities(raritiesList);
    }, []);

    return (
        <>
            <Header>{STRINGS.rarities}</Header>
            <Description>{STRINGS.raritiesDescription}</Description>
            <Grid items={rarities} style={CustomGrid} />
        </>
    );
}

export default Rarities;