
import ROUTES from '@utils/routes';
export const STRINGS = {
    campaign: "Finney",
    campaignPack: "Finney Standard Print",
    campaignBox: "Finney Deluxe Edition",

    homePage: {
        headline: 'Finney’s story has been revealed.',
        subtext: 'Follow him on his journey to planet earth as he unlocks the secrets of cryptocurrencies and blockchain technology.',
        launchAnnoucement: 'Launching in…'
    },

    ribbon: "Help Mushy reach enlightenment",
    rarities: "what you’ll find on Finney Vol. 1",
    raritiesDescription: "21 unique artworks accompanied by the chapter text which makes up Finney’s “Immutable Journey”. 5 rarity tiers ranging from animated frequencies to speech bubbles + a few airdrop surprises to keep you on your toes.",
    aboutTitle: "What is Finney?",
    aboutDescription: <>
        This is a digital collection of comic book style NFT cards available only this August only on <a href='https://wallet.wax.io' target='_blank' rel='noreferrer noopener'>WAX</a>! Collect, trade, and learn the story of Finney through beautifully illustrated cards.
    </>
    ,

    home: "Home",
    reader: "Reader",
    packRips: "Pack Rips",
    inventory: "Inventory",
    leaderboard: "Leaderboard",
    faq: "FAQ",

    selectComicBook: "Select Comic Book",
    noComicsAvailable: "You need to have at least one cover to be able to read the comic",

    totalPacksMessage: "{0} Ember Packs in total",
    totalPointsMessage: "{0} Points earned in total",

    logIn: "Log In",
    logOut: "Log Out",
    notFound: "Not Found",
    loading: "Loading",
    loadingEl: "Loading...",
    confirm: "Confirm",
    cancel: "Cancel",
    close: "Close",
    error: "Error",
    defaultErrorMessage: "An error has occurred!",

    goToHome: "Home Page",

    day: "day",
    days: "days",
    hour: "hour",
    hours: "hours",
    minute: "minute",
    minutes: "minutes",
    second: "second",
    seconds: "seconds",

    shop: "Shop",
    buyPack: "Buy Standard Pack",
    buyBox: "Buy Deluxe Pack",
    buyPacks: "Buy Packs",
    buyBoxes: "Buy Deluxe Packs",
    buyAPack: "Buy a Standard Pack",
    buyABox: "Buy a Deluxe Pack",
    buyMorePacks: "Buy more Packs",
    buyASinglePack: "Buy a Standard Pack",
    singlePack: "Single Pack",
    packsAmount: "Standard Print amount:",
    boxesAmount: "Deluxe Edition amount:",

    soldOut: "Sold Out",
    secondaryMarketTitle: "Couldn't buy a pack here? Try the secondary market:",

    burntPacks: "Burnt packs do NOT contain NFTs",
    burntPacksFuture: "Filters for Full Pack's coming soon",

    openPack: "Open Asset",
    openPacks: "Open",
    openAndBurn: "Open and Burn",
    openPackConfirmation: "Are you sure you want to open {0} with mint {1}?",
    openPackSuccess: "Pack successfully opened!",

    goToPackRips: "Go To Pack Rips",
    noRips: "You don't have any opened pack yet.",
    viewRip: "View Rips",
    viewAsset: "View Asset",
    burn: "Burn",
    burnConfirmationTitle: "Are you sure you want to burn the asset {0} with mint #{1}?",
    openAndBurnConfirmationTitle: "Are you sure you want to open and burn the asset {0} with mint #{1}?",
    burnConfirmationWarning: "Warning: burning assets is an irreversible action.",
    viewCollection: "View your Finney collection on AtomicHub",
    viewEntireCollection: "View the entire Finney collection",

    wax: "WAX",

    buyPacksFor: "Buy {0} Standard Prints for {1}",
    buyBoxesFor: "Buy {0} Deluxe Editions for {1}",
    buyPackConfirmation: "Are you sure you want to buy a Standard Print for {0}?",
    buyPacksConfirmation: "Are you sure you want to buy {0} Standard Print for a total of {1}?",
    buyBoxConfirmation: "Are you sure you want to buy a Deluxe Edition for {0}?",
    buyBoxesConfirmation: "Are you sure you want to buy {0} Deluxe Editions for a total of {1}?",
    packPurchaseLimit: "You can purchase {0} Standard Prints within {1} min.",
    boxPurchaseLimit: "You can purchase {0} Deluxe Editions within {1} min.",

    successfulBuy: "Congratulations!",
    buySuccessMessage: "Your purchase has been successful. Check out your inventory.",

    assetNotMintedDisclaimer: "Mint number is being calculated...",

    packContents: "5 Cards",
    boxContents: "20 Cards",
    packProbabilities: "Probabilities",
    packDetailsDescription: "Finney.",

    packChances: [
        { rarity: 'Cover', probability: '100%' },
        { rarity: 'Base - Ink', probability: '27.37%' },
        { rarity: 'Base - Colour', probability: '27.37%' },
        { rarity: 'Timelapse - (Ink to Colour)', probability: '24.56%' },
        { rarity: 'Narrated - Ink', probability: '13.68%' },
        { rarity: 'Narrated - Colour', probability: '7.02%' },
        { rarity: 'Platinum Frequency - (/210)', note: 'additional chance', probability: '31.58%' },
        { rarity: 'Gold Frequency - (/21)', note: 'additional chance', probability: '2.46%' },
        { rarity: "Dark Rhodium Frequency - (1/1)", note: 'additional chance - cards 1 to 9 only', probability: '0.15%' }
    ],

    boxChances: [
        { rarity: 'Cover', probability: '100%' },
        { rarity: 'Base - Ink', probability: '26.12%' },
        { rarity: 'Base - Colour', probability: '26.12%' },
        { rarity: 'Timelapse (Ink to Colour)', probability: '22.71%' },
        { rarity: 'Narrated - Ink', probability: '15.33%' },
        { rarity: 'Narrated - Colour', probability: '9.71%' },
        { rarity: 'Platinum Frequency - (/210)', note: 'additional chance', probability: '67.80%' },
        { rarity: 'Gold Frequency - (/21)', note: 'additional chance', probability: '7.91%' },
        { rarity: "Dark Rhodium Frequency - (1/1)", note: 'additional chance - cards 10 to 21 only', probability: '0.32%' }
    ],

    rank: "Rank",
    account: "Account",
    name: "Name",
    points: "Points",
    emberScore: "Ember Score",
    emberPacks: "Ember Packs",
    emberScoreChartTitle: "Ember Score Chart",

    emberEventTitle: "The Ember Burn Event",
    emberEventDescriptionTitle: "What are the points earned per asset?",
    emberEventDescription: "This is the breakdown of the points earned by burning your Dark Pinup cards. The points earned are structured to be fair, with special incentives given to those that purchased and earned Special Candlelights, Wrappers, as well as Burnt Flyers. Everyone is rewarded for participating in this burn event. Special favor will be bestowed upon the top 10, with differing prizes for participants closest to rank #1.",

    emberEventDescriptionTitle2: "Where can I get more info?",
    emberEventDescription2: <>Find more information on the Ember Burn event in the blog post <a href="https://facings.medium.com/dark-pinup-ember-burn-event-6f5de39c77b2" target='_blank' rel='noreferrer noopener' >here</a>. Cards can be burned from the <a href={`/#${ROUTES.inventory}`}>inventory page</a></>,

    emberEventDescriptionTitle3: "How can I acquire more items to burn?",

    emberPackDetails: [
        { label: 'Burnt Flyer', points: 5 },
        { label: '(EMPTY) Burnt Full Pack Wrapper', points: 30 },
        { label: 'Special Candlelight', points: 60 },
        { label: '(EMPTY) Burnt Collector’s Box Wrapper', points: 45 },
        { label: 'Base', points: 35 },
        { label: 'Noir', points: 35 },
        { label: 'Memento', points: 55 },
        { label: 'Print', points: 55 },
        { label: 'Model Mark', points: 130 },
        { label: 'Artist Sig', points: 130 },
        { label: 'Prisma', points: 140 },
        { label: 'Candlelight', points: 1450 },
        { label: 'Special Memento', points: 37500 },
        { label: 'Points Needed per Ember Pack', points: 900 }
    ],

    faqItems: [
        {
            question: "What is the Finney NFT digital collectible series?",
            answer:
            <>
                <p>
                    Collectors can buy, sell, and trade Finney digital trading cards (called NFTs). Each NFT trades on the WAX blockchain which means that you enjoy true ownership of the asset. They are authentic, unique and can never be altered. Learn more about Finney NFT digital collectibles <a href='https://finney.world' target='_blank' rel='noreferrer noopener'>here</a>.
                </p>
            </>
        },
        {
            question: "What can collectors do with their NFTs?",
            answer:
            <>
                <p>
                    Because Finney Cards NFTs are built using WAX Blockchain technology, you can not only buy and sell digital Finney Cards trading cards on a number of marketplaces - you can also instantly trade cards from your collection. The WAX Blockchain ensures your Finney Cards collectible is 100% verifiable as authentic and can never be changed or duplicated.
                </p>
                <p>
                    All you need is a <a href='https://wallet.wax.io' target='_blank' rel='noreferrer noopener'>WAX Cloud Wallet</a> account to get started.
                </p>
            </>
        },
        {
            question: "How do I buy Packs or Cards on WAX?",
            answer:
            <>
                <p>
                    Log in with a <a href='https://wallet.wax.io' target='_blank' rel='noreferrer noopener'>WAX Cloud Wallet account</a>, select your packs, and complete the purchase. Get them before they sell out!
                </p>
            </>
        },
        {
            question: "How do I trade WAX Packs or cards to someone else?",
            answer:
            <>
                <p>
                    You can transfer your Finney Cards WAX Packs and cards to someone else by visiting your WAX Cloud Wallet NFT inventory and clicking on the transfer icon on the top of the NFT. Then enter the wallet name of the community member that you wish to trade with and click Send.
                </p>
            </>
        },
        {
            question: "What are NFTs?",
            answer:
            <>
                <p>
                    NFT stands for Non Fungible Token and is a way of creating unique digital collectible assets. Refer to this article for more information on <a href='https://www.forbes.com/advisor/investing/nft-non-fungible-token/' target='_blank' rel='noreferrer noopener'>NFTs</a>.
                </p>
            </>
        },
        {
            question: "What is WAX?",
            answer:
            <>
                <a href='https://wax.io' target='_blank' rel='noreferrer noopener'>WAX</a> is a blockchain tailored for the end user experience with tools that allow collectors to buy, sell, and trade NFTs with ease. Since 2017, WAX has facilitated the trade of more than 100 million digital items.
            </>
        },
        {
            question: "What can I do with my Digital Collectible NFTs?",
            answer:
            <>
                <p>
                    NFTs can be traded, sold, collected, and otherwise enjoyed however the collector wants. The collectibles are unique and the images are stored on <a href='https://ipfs.io' target='_blank' rel='noreferrer noopener'>IPFS</a>, so your NFTs will exist forever in the growing WAX ecosystem.
                </p>
            </>
        },
        {
            question: "How can I buy WAX?",
            answer:
            <>
                <p>
                    WAXP is available on <a href='https://bittrex.com/' target='_blank' rel='noreferrer noopener'>Bittrex</a> as well as <a href='http://kucoin.com' target='_blank' rel='noreferrer noopener'>Kucoin</a>. Learn more about where and how to buy WAXP <a href='https://eos-amsterdam.medium.com/the-complete-guide-to-the-wax-cloud-wallet-f14f95788012' target='_blank' rel='noreferrer noopener'>here</a>.
                </p>
            </>
        },
        {
            question: "How do I know my NFTs are authentic?",
            answer:
            <>
                <p>
                    {"Always refer to the verified checkmark on the collection to ensure your NFTs are created by the author's account."}
                </p>
            </>
        },
        {
            question: "Is WAX bad for the environment?",
            answer:
            <>
                <p>
                    No, WAX is 125,000x more energy efficient than ETH as explained <a href='https://wax-io.medium.com/wax-celebrates-earth-month-9f3164749e46' target='_blank' rel='noreferrer noopener'>here</a>.
                </p>
            </>
        },
        {
            question: "Who is FACINGS?",
            answer:
            <>
                <p>
                    FACINGS is an NFT collection publishing platform. FACINGS puts everything you need to launch end-to-end NFT campaigns in one place, from payment processing to security- making it easier than ever before to integrate NFTs into your strategy.
                </p>
            </>
        },
        {
            question: "How can I get support?",
            answer: <><p>Please email <a href='mailto:hello@facings.io'>hello@facings.io</a> for support.</p></>
        }
    ],

    availableIn: "Available in",
    comingSoon: "Coming Soon",
    releaseDate: "July 8th",
    releaseTime: "09:00 AM PDT | 12:00 PM EDT",

    formTitle: "Receive exclusive updates on Finney Cards and be the first to know Finney's story",
    formSuccessMessage: "Thank you",
    formButton: "Be the first to hear from Finney",
    websiteLink: "Learn more about Finney",

    cpuErrorMessage: <>Stake some CPU <a href="https://www.wax.bloks.io/wallet/resources/stake" target='_blank' rel='noreferrer noopener'>here</a> to solve the issue</>,
    netErrorMessage: <>Stake some NET <a href="https://www.wax.bloks.io/wallet/resources/stake" target='_blank' rel='noreferrer noopener'>here</a> to solve the issue</>,
    waxWallet: <>{"Don't have a WAX wallet yet? Create one "}<a href='https://wallet.wax.io' target='_blank' rel='noreferrer noopener'>here</a></>
};

export default STRINGS;
