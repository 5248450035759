
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from '@emotion/styled';

import "swiper/swiper.min.css";
import "swiper/components/zoom/zoom.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";

import SwiperCore, {
    Zoom, Navigation, Pagination, Keyboard
} from 'swiper/core';

SwiperCore.use([ Zoom, Navigation, Pagination, Keyboard ]);

const Video = styled.video(() => ({
    height: '100%',
    maxWidth: '100%'
}));

export default function ComicReader({ items }) {
    let itemsToRender = items.map((item, index) =>
        (<SwiperSlide key={index}>
            <div className="swiper-zoom-container">
                { item.type === 'video' ?
                    <Video muted autoPlay loop playsInline>
                        <source src={item.srcImg} type="video/mp4" />
                    </Video>
                    : <img src={item.srcImg} />
                }
            </div>
        </SwiperSlide>)
    );

    return (
        <Swiper
            pagination={{ "type": "fraction" }}
            style={{
                '--swiper-navigation-color': '#FFF',
                'height': '100vh',
                paddingBottom: 56
            }}
            zoom
            navigation
            className="mySwiper"
            keyboard={{ "enabled": true }}
        >
            {itemsToRender}
        </Swiper>
    );
}
