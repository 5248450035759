import styled from '@emotion/styled';
import RES from '@utils/resources';
import SocialMediaLink from '@components/SocialMediaLink';

import PoweredByWax from '@images/PoweredByWax.png';
import AtomicAssetsLogo from '@images/atomic-assets-logo.png';
import FacingsLogo from '@images/FACINGS_Logo_Negative.png';
import TelegramLogo from '@images/telegram-icon.svg';
import TwitterLogo from '@images/twitter-icon.svg';
import DiscordLogo from '@images/discord.svg';

const StyledFooter = styled.div(({ theme, style }) => ({
    backgroundColor: theme.colors.primary,
    padding: theme.spacing.xs,
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    [theme.mediaQuery.tabletUp]: {
        justifyContent: 'space-evenly',
        flexDirection: 'row'
    },
    ...style
}));

const Row = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing.xs
}));

const FacingsGroup = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}));

const FooterLink = styled.a(({ theme }) => ({
    cursor: 'pointer',
    marginTop: theme.spacing.s,
    userSelect: 'none',
    [theme.mediaQuery.tabletUp]: {
        marginTop: 0
    }
}));

function Footer({ style }) {
    return (
        <StyledFooter>
            <FooterLink href={RES.externalLinks.wax.link} target="_blank" rel="noopener noreferrer">
                <img style={{ width: '150px' }} src={PoweredByWax}  alt={RES.externalLinks.wax.label} />
            </FooterLink>
            <FooterLink href={RES.externalLinks.atomicAssets.link} target="_blank" rel="noopener noreferrer">
                <img style={{ width: '150px' }} src={AtomicAssetsLogo} alt={RES.externalLinks.atomicAssets.label} />
            </FooterLink>
            <FacingsGroup>
                <FooterLink  href={RES.externalLinks.facings.link} target="_blank" rel="noopener noreferrer">
                    <img style={{ width: '70px', margin: '0 25px' }} src={FacingsLogo} alt={RES.externalLinks.facings.label} />
                </FooterLink>
                <Row>
                    <SocialMediaLink
                        link={RES.externalLinks.facingsTelegram.link}
                        logo={TelegramLogo}
                        alt={RES.externalLinks.facingsTelegram.label}
                    />
                    <SocialMediaLink
                        link={RES.externalLinks.facingsTwitter.link}
                        logo={TwitterLogo}
                        alt={RES.externalLinks.facingsTwitter.label}
                    />
                    <SocialMediaLink
                        link={RES.externalLinks.facingsDiscord.link}
                        logo={DiscordLogo}
                        alt={RES.externalLinks.facingsDiscord.label}
                    />
                </Row>
            </FacingsGroup>
        </StyledFooter>
    );
}

export default Footer;
