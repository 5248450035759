import styled from '@emotion/styled';
import StyledModal from '@components/Modal';
import StyledButton from '@components/Button';
import STRINGS from '@utils/strings';

const Title = styled.div(({ theme }) => ({
    ...theme.typography.h6,
    marginBottom: theme.spacing.m,
    display: 'flex',
    justifyContent: 'center'
}));

const ButtonContainer = styled.div(() => ({
    display: 'flex',
    justifyContent: 'space-evenly',
    margin: 'auto'
}));

function StyledConfirmationModal({ children, show, onConfirm, onClose, title, confirmationButtonLabel=STRINGS.confirm, cancelButtonLabel=STRINGS.cancel, style, ...otherProps }) {
    return (<>
        <StyledModal show={show} onClose={onClose}>
            <Title>{title}</Title>
            {children}
            <ButtonContainer>
                <StyledButton style={{ marginRight: 16 }} onClick={onConfirm} >{confirmationButtonLabel}</StyledButton>
                <StyledButton secondary onClick={onClose}>{cancelButtonLabel}</StyledButton>
            </ButtonContainer>
        </StyledModal>
    </>);
}

export default StyledConfirmationModal;
