import React from 'react';
import styled from '@emotion/styled';
import STRINGS from '@utils/strings';

const Header = styled.h3(({ theme }) => ({
    ...theme.typography.h3,
    fontWeight: '700',
    marginTop: theme.spacing.l,
    marginBottom: theme.spacing.s,
    textTransform: 'uppercase',
    textAlign: 'center',
    color: theme.colors.secondaryDark
}));

const Description = styled.p(({ theme }) => ({
    ...theme.typography.p,
    textAlign: 'center',
    color: theme.colors.secondaryDark,
    maxWidth: '60ch',
    'a': {
        color: theme.colors.tertiary,
        fontWeight: 600,
        '&:hover': {
            color: theme.colors.primaryDark
        }
    }
}));

function TextSection({ title, paragraph }) {
    return (
        <>
            <Header>{title}</Header>
            <Description>{paragraph}</Description>
        </>
    );
}

export default TextSection;