/* eslint import/extensions: "off" */

import React from 'react';
import styled from '@emotion/styled';
import BackgroundImageLayout from '@context/Home/BackgroundImage';
import TextSection from '@context/Home/TextSection';
import Rarities from '@context/Home/Rarities';
import STRINGS from '@utils/strings';

const Container = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing.s,
    padding: `0 ${theme.spacing.xs}`
}));

function Home() {

    return (
        <>
            <BackgroundImageLayout />
            {/* <Container>
                <Image alt={STRINGS.campaignPack} src={RES.images.packAndBox} />
            </Container>
            { isReleased() ?
                <Container>
                    <StyledButton>
                        {STRINGS.buyPacks}
            <InfoContainer>
                <ColInfoContainer>
                    <LogoImage alt={STRINGS.campaign} src={RES.images.logo} />
                    <Headline>
                        {STRINGS.homePage.headline}
                    </Headline>
                    <Subtext>
                        {STRINGS.homePage.subtext}
                    </Subtext>
                </ColInfoContainer>
                { isReleased() ? null
                    : <ColInfoContainer>
                        <LauchAnnouncement>
                            {STRINGS.homePage.launchAnnoucement}
                        </LauchAnnouncement>
                        <Countdown />
                        <ReleaseDate>
                            {STRINGS.releaseDate}
                        </ReleaseDate>
                        <ReleaseTime>
                            {STRINGS.releaseTime}
                        </ReleaseTime>
                    </ColInfoContainer>
                }
                { isReleased() ?
                    <Container>
                        <StyledButton onClick={() => { history.push(ROUTES.buypacks);}}>
                            {STRINGS.buyPacks}
                        </StyledButton>
                    </Container>
                    : null
                }
                <ColInfoContainer>
                    <StyledButton
                        style={buttonMargin}
                        onClick={() => setShowFormModal(true)}
                        secondary={isReleased() ? true : false}
                    >
            { isReleased() ? STRINGS.formButtonPostRelease : STRINGS.formButtonPreRelease }
                    </StyledButton>
                </ColInfoContainer>
                <Container>
                    <Grid items={RES.samples} style={CustomGrid} />
                </Container>
                : null
            } */}
            <Container>
                <TextSection title={STRINGS.aboutTitle} paragraph={STRINGS.aboutDescription} />
            </Container>
            <Container>
                <Rarities />
            </Container>
        </>
    );
}

export default Home;
