import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useEffect } from 'react';
import x from '@images/close-icon.svg';
import xWhite from '@images/close-icon-white.svg';

const ModalBackgroundOverlay = styled.div(() => ({
    backgroundColor: 'rgba(6,2,1,0.5)',
    position: 'fixed',
    display: 'flex',
    verticalAlign: 'middle',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 999
}));

const Modal = styled.div(({ theme, dark, style }) => ({
    ...theme.typography.h6,
    color: dark ? theme.colors.backgroundColor : theme.colors.tertiary,
    backgroundColor: dark ? theme.colors.primary : theme.colors.backgroundColor,
    padding: theme.spacing.s,
    border: dark ? 'none' : `solid ${theme.colors.backgroundColor} 1px`,
    borderRadius: theme.borderRadius.s,
    fontWeight: 'bold',
    width: '40vw',
    minWidth: '300px',
    margin: 'auto',
    textAlign: 'center',
    ...style
}));

const XCloseButtonContainer = styled.div(({ theme }) => ({
    marginLeft: 'auto',
    marginBottom: theme.spacing.xxs,
    cursor: 'pointer',
    width: theme.spacing.xs,
    color: theme.colors.primary,
    transition: theme.transition.default,
    '&:hover': {
        color: theme.colors.primaryDark
    }
}));

function StyledModal({ children, show, onClose, dark, style, ...otherProps }) {
    const theme = useTheme();

    useEffect(() => {
        document.body.style.overflow = show ? 'hidden' : 'auto';
    }, [show]);

    return (<>
        {show ?
            <ModalBackgroundOverlay>
                <Modal {...otherProps} dark={dark} style={style} >
                    <XCloseButtonContainer onClick={onClose}>
                        <img style={{ width: theme.spacing.xs, height: theme.spacing.xs }} src={dark ? xWhite : x} />
                    </XCloseButtonContainer>
                    {children}
                </Modal>
            </ModalBackgroundOverlay>
            :
            null}
    </>);
}

export default StyledModal;
