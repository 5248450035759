export const RELEASE_TEST = process.env.REACT_APP_FEATURE_RELEASE_TEST === 'true';
export const RELEASE_DATE = new Date(process.env.REACT_APP_RELEASE_DATE);

export const isReleased = () => {
    if (RELEASE_TEST) {
        return true;
    } else {
        return RELEASE_DATE.getTime() - Date.now() <= 0;
    }
};

export const ATOMICASSETS_ACCOUNT = 'atomicassets';
export const ATOMICASSETS_TRANSFER_ACTION = 'transfer';
export const ATOMICASSETS_BURN_ACTION = 'burnasset';

export const ATOMICDROPS_ACCOUNT = 'atomicdropsx';
export const ATOMICDROPS_CLAIM_ACTION = 'claimdrop';
export const ATOMICDROPS_TABLE = 'drops';

export const SCOREBOARD_ACCOUNT = 'theemberburn';
export const SCOREBOARD_SCORES_TABLE = 'scores';

export const EOSIO_ACCOUNT = 'eosio.token';

export const BOOST_ACCOUNT = 'bw.facings';
export const BOOST_ACTION = 'boost';

// TODO: review this
export const PACK_UNLOCKED = true;
export const PACK_OPENER_UNBOXED_ACTION = 'unboxed';

export const MEMOS = {
    UNBOX: 'unbox',
    DEPOSIT: 'deposit',
    DEPOSIT_COLLECTION_RAM: 'deposit_collection_ram:'
};

//Todo - Use .env files for this stuff that changes on testnet vs. prod

export const ENV = process.env.REACT_APP_ENVIRONMENT === "prod" ? "" : "test.";

export const PACK_DROP_ID = parseInt(process.env.REACT_APP_PACK_DROP_ID);
export const BOX_DROP_ID = parseInt(process.env.REACT_APP_BOX_DROP_ID);

export const IPFS_ENDPOINT = process.env.REACT_APP_IPFS_ENDPOINT;

export const HYPERION_ENDPOINT = process.env.REACT_APP_WAX_HYPERION_ENDPOINT;

export const AA_ENDPOINT = process.env.REACT_APP_AA_ENDPOINT;

export const PACK_OPENER_ACCOUNT = process.env.REACT_APP_PACK_OPENER_ACCOUNT;
export const PACK_OPENER_PACKSTATUS_TABLE = 'packstatus';

export const WAX_RPC_ENDPOINT = process.env.REACT_APP_WAX_RPC;

export const COLLECTION_NAME = process.env.REACT_APP_COLLECTION_NAME;

export const BOX_TEMPLATE_ID = process.env.REACT_APP_BOX_TEMPLATE_ID;
export const PACK_TEMPLATE_ID = process.env.REACT_APP_PACK_TEMPLATE_ID;

export const EMBER_PACK_SCORE_AMOUNT = 900;

export const SCHEMAS = process.env.REACT_APP_SCHEMAS.split(",");

export const SHOW_FAQ = process.env.REACT_APP_FEATURE_SHOW_FAQ === 'true';
export const SHOW_HEADER = process.env.REACT_APP_FEATURE_SHOW_HEADER === 'true';
export const WAX_SIGN_IN = process.env.REACT_APP_FEATURE_WAX_SIGN_IN === 'true';
export const SHOW_BUY_PAGE = process.env.REACT_APP_FEATURE_BUY_PAGE === 'true';
export const INVENTORY_UNPACK = process.env.REACT_APP_FEATURE_INVENTORY_UNPACK === 'true';
export const SHOW_PACK_HISTORY = process.env.REACT_APP_FEATURE_SHOW_PACK_HISTORY === 'true';

