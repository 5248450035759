export function numberWithCommas(n) {
    let parts=n.toString().split(".");
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
}

export function isCPUErrorMessage(message) {
    return message.includes("billed CPU time");
}

export function isNETErrorMessage(message) {
    return message.includes("transaction net usage is too high");
}