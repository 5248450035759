import { Route, Redirect, Switch } from 'react-router-dom';
import styled from '@emotion/styled';
import Home from '@context/Home/Home';
import BuyPacks from '@context/BuyPacks/BuyPacks';
import Inventory from '@context/Inventory';
import NotFound from '@context/NotFound';
import PackRips from '@context/PackRips';
import FAQ from '@context/FAQ';
import Reader from '@context/Reader';
import ROUTES from '@utils/routes';
import { SHOW_FAQ, WAX_SIGN_IN, SHOW_HEADER, SHOW_BUY_PAGE, SHOW_PACK_HISTORY } from '@utils/globals';
import { withUAL } from 'ual-reactjs-renderer';
import { withRouter } from 'react-router-dom';

const Container = styled.div(({ theme }) => ({
    width: '100%',
    minHeight: `calc(100vh - ${theme.size.headerHeight} - ${theme.size.footerHeight})`,
    margin: SHOW_HEADER ? `${theme.size.headerHeight} 0px 0px 0px` : 0
}));

function Content({ ual, location, history }) {
    if (WAX_SIGN_IN && !ual.activeUser) {
        if (location.pathname.includes(ROUTES.inventory) ||
            location.pathname.includes(ROUTES.reader) ||
            location.pathname.includes(ROUTES.packrips)) {
            history.push(ROUTES.home);
        }
    }

    return (
        <Container>
            <Switch>
                <Redirect exact from="/" to={ROUTES.home} />
                <Route path={ROUTES.home} component={Home} />
                {
                    SHOW_BUY_PAGE ?
                        <Route path={ROUTES.buypacks} component={BuyPacks} />
                        : null
                }
                {
                    SHOW_FAQ ?
                        <Route path={ROUTES.faq} component={FAQ} />
                        : null
                }
                {
                    WAX_SIGN_IN ?
                        <>
                            <Route path={ROUTES.reader} component={Reader} />
                            <Route path={ROUTES.inventory} component={Inventory} />
                            { SHOW_PACK_HISTORY ? <Route path={ROUTES.packrips} component={PackRips} /> : null }
                        </>
                        : null
                }
                <Route path={ROUTES.notfound} component={NotFound} />
                <Redirect to={ROUTES.notfound} />
            </Switch>
        </Container>
    );
}

export default WAX_SIGN_IN ? withUAL(withRouter(Content)) : withRouter(Content);
